import React from "react";

function FinancingOptions() {
  return (
    <div className="w-full flex justify-center">
      <div className="w-full relative flex flex-col pt-40 lg:pt-10 lg:pt-28 lg:px-0 px-5 pb-12 max-w-5xl">
        <h2 className="text-3xl shrink-0 text-dark text-start font-bold ">
          Wie kann mudioo noch finanziert werden?
        </h2>
        <div className="ml-0 mt-10 lg:pr-0 pr-10">
          <div className="flex max-w-3xl">
            <div className="w-8 h-8 shrink-0 rounded-full bg-primary mr-2 justify-center items-center flex text-white font-bold">
              1
            </div>
            <div>
              <h2 className="text-lg font-semibold mt-0.5">
                Externe Förderungen
              </h2>
              <p className="mt-2 text-darkgray ">
                Für Förderanträge bei externen Förderern können auch
                langfristige Nutzungsverträge für mudioo abgeschlossen werden.
                Diese Kosten können im Rahmen von Förderanträgen eingereicht
                werden. <br />→ 5 Jahre: 6500€ / 3 Jahre: 3900€
              </p>
            </div>
          </div>

          <div className="flex mt-10 max-w-3xl">
            <div className="w-8 h-8 shrink-0 rounded-full bg-primary mr-2 justify-center items-center flex text-white font-bold">
              2
            </div>
            <div>
              <h2 className="text-lg font-semibold mt-0.5">
                Refinanzierung durch Besucher:innen
              </h2>
              <p className="mt-2 text-darkgray ">
                Sie haben die Möglichkeit von Ihren Besucher:innen einen kleinen
                Aufpreis zu veranschlagen. Dabei werden die von Ihnen erstellten
                Inhalte zunächst gesperrt. Diese können dann nur durch einen von
                Ihnen ausgegebenen QR- oder Freischaltcode geöffnet werden.
              </p>
            </div>
          </div>

          <div className="flex mt-10 max-w-3xl">
            <div className="w-8 h-8 shrink-0 rounded-full bg-primary mr-2 justify-center items-center flex text-white font-bold">
              3
            </div>
            <div>
              <h2 className="text-lg font-semibold mt-0.5">Sponsoren</h2>
              <p className="mt-2 text-darkgray ">
                Im Rahmen des Sponsoring-Programms können Sie beispielsweise
                regionale Unternehmen akquirieren, durch die die Kosten für die
                mudioo Software getragen werden. Im Gegenzug ermöglicht es die
                mudioo-Software, dass Sie dem jeweiligen Unternehmen dann zwei
                kleine Werbeflächen in Ihrem Multimediaguide anbieten können.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinancingOptions;
