import React from "react";

function Targetgroup2() {
  return (
    <div className="w-full flex justify-evenly mt-24 pt-10  ">
      <div className="w-full max-w-5xl justify-center items-start  flex-wrap ipad:px-0 px-5 flex lg:mb-20 ">
        <h2 className="font-bold lg:text-center w-full mb-8 text-center text-3xl leading-[44px] ">
          Für wen ist mudioo geeignet?
        </h2>
        <TargetGroupItem txt={"Museen"} />
        <TargetGroupItem version={1} txt={"Zeitlich begrenzte Ausstellungen"} />
        <TargetGroupItem txt={"Tourismusverbände"} />
        <TargetGroupItem txt={"Stadtrundgänge"} />
        <TargetGroupItem version={1} txt={"Burgen"} />
        <TargetGroupItem version={1} txt={"Schlösser"} />
        <TargetGroupItem version={1} txt={"Kirchen"} />
        <TargetGroupItem txt={"Botanische Gärten"} />
        <TargetGroupItem txt={"Parks"} />
        <TargetGroupItem version={1} txt={"Gedenkstätten"} />
        <TargetGroupItem version={1} txt={"Denkmäler"} />
      </div>
      {/* <div className="w-full max-w-5xl justify-between items-start  flex-col ipad:px-0 px-5 flex lg:mb-20 ">
        <h2 className="font-bold lg:text-center w-full  text-center text-3xl mt-8 lg:mt-8 leading-[44px] ">
          Für wen ist mudioo geeignet?
        </h2>
  
        <div className="grid grid-cols-3 w-full gap-3 mt-10">
          <TargetGroupItem txt={"Museen"} />
          <TargetGroupItem
            version={1}
            txt={"Zeitlich begrenzte Ausstellungen"}
          />
          <TargetGroupItem txt={"Tourismusverbände/ Stadtrundgänge"} />
          <TargetGroupItem version={1} txt={"Burgen/ Schlösser/ Kirchen"} />
          <TargetGroupItem txt={"Botanische Gärten / Parks"} />
          <TargetGroupItem version={1} txt={"Gedenkstätten / Denkmäler"} />
        </div>
      </div> */}
    </div>
  );
}

export default Targetgroup2;

const TargetGroupItem = ({ txt, version }) => {
  return (
    <div
      // style={{ boxShadow: "0px 0px 15px #f2f3fa" }}
      className={`overflow-hidden relative py-2 px-5 m-2 shrink-0 bg-lightgray flex justify-center font-semibold text-darkgray text-lg rounded-full `}
    >
      {txt}
      {/* <div className="bg-gradient-to-t from-[#3545D1] to-[#616FDB] rounded-full h-36 w-36 absolute -right-20" />
      <div className="bg-gradient-to-b from-[#3545D1] to-[#616FDB] rounded-full h-36 w-36 absolute -left-16 -top-28" /> */}
    </div>
  );
};
