import { motion, useScroll } from "framer-motion";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import "../App.css";
function Menubar({ selected }) {
  const { pathname } = useLocation();
  const [menuBg, setMenuBg] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { scrollY } = useScroll();

  useEffect(() => {
    // if (window.location.pathname !== "/" && window.location.pathname !== "") {
    //   setMenuBg(true);
    // }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    return scrollY.onChange((v) => {
      if (scrollY.current > 50) {
        setMenuBg(true);
      } else {
        // if (window.location.pathname === "/") {
        //   setMenuBg(false);
        // }
        setMenuBg(false);
      }
    });
  }, [scrollY]);

  return (
    <div className="relative z-50">
      <motion.div
        className={`w-full h-20 top-0 fixed z-50 transition-all border-border px-5 lg:px-10 flex justify-center  items-center ${
          menuBg ? "bg-white border-b pt-0" : "lg:pt-10 pt-0"
        } ${showMobileMenu ? "bg-white" : ""} `}
      >
        <div className="w-full flex items-center h-full justify-between max-w-5xl">
          <div className="flex items-center">
            <a
              href="/"
              className="flex flex-row items-center text-xl text-primary"
            >
              <div className="w-8 mt-2">
                <img
                  alt="Logo"
                  className="-mt-2"
                  src={require("../Assets/logo_blue.png")}
                />
              </div>
              <div
                className={`text-2xl font-800 text-primary font-extrabold  ml-2 font-nunito`}
              >
                mudioo
              </div>
            </a>
            {/* <div className={`font-bold ml-20 text-center `}>
              <Link
                className={`mx-4 ${
                  window.location.pathname === "/"
                    ? "text-primary"
                    : "text-dark"
                }`}
                to="/"
              >
                START
              </Link>
              <a
                className={`mx-8 ${
                  window.location.pathname === "/aboutus"
                    ? "text-primary"
                    : "text-dark"
                }`}
                href="/aboutus"
              >
                ÜBER UNS
              </a>
              <a
                className={`mx-4 ${
                  window.location.pathname === "/contact"
                    ? "text-primary"
                    : "text-dark"
                }`}
                href="/contact"
              >
                KONTAKT
              </a>
            </div> */}
          </div>
          <div className="w-1/3 hidden lg:flex justify-end items-center">
            <a
              href="/"
              className={`${
                selected === "home"
                  ? "text-primary underline"
                  : "text-dark hover:text-secondary"
              }  font-bold mr-8 `}
            >
              Übersicht
            </a>
            <a
              href="/pricing"
              className={`${
                selected === "pricing"
                  ? "text-primary underline"
                  : "text-dark hover:text-secondary"
              }  font-bold mr-8 `}
            >
              Preise
            </a>
            <a
              href="/contact"
              className={`${
                selected === "contact"
                  ? "text-primary underline"
                  : "text-dark hover:text-secondary"
              }  font-bold mr-8 `}
            >
              Kontakt
            </a>
            <a
              className={`rounded-full ml-8 hover:bg-secondary transition-all px-6 py-2.5 text-white border-0 border-primary text-sm font-semibold bg-primary`}
              href="https://mudioo-cms.de"
              target="_blank"
            >
              Login
            </a>
          </div>

          <div
            onClick={() => setShowMobileMenu(!showMobileMenu)}
            className={`flex justify-center lg:hidden items-center rounded-full transition-all h-16 aspect-square ${
              showMobileMenu ? "rotate-45" : ""
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-8 h-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 9h16.5m-16.5 6.75h16.5"
              />
            </svg>
          </div>
        </div>
      </motion.div>

      <div
        className={`h-screen w-full bg-white z-60 fixed left-0 flex flex-col items-center  transition-all ${
          showMobileMenu ? "flex opacity-1" : "hidden opacity-0"
        }`}
      >
        <div className="h-28 border-b border-border w-full" />
        <a
          className={`text-lg w-full px-5 py-5 font-bold border-b border-border ${
            selected === "home" ? "text-primary" : "text-dark "
          }`}
          href="/"
        >
          Übersicht
        </a>
        <a
          className={`text-lg w-full px-5 py-5 font-bold border-b border-border ${
            selected === "pricing" ? "text-primary" : "text-dark "
          }`}
          href="/pricing"
        >
          Preise
        </a>
        <a
          className={`text-lg w-full px-5 py-5 font-bold border-b border-border ${
            selected === "contact" ? "text-primary" : "text-dark "
          }`}
          href="/contact"
        >
          Kontakt
        </a>
        <a
          className=" text-lg w-full px-5 py-5 font-bold border-b border-border"
          href="https://mudioo-cms.de"
          target="_blank"
        >
          Login
        </a>
      </div>
    </div>
  );
}

export default Menubar;
