import React, { useState, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

import { ReactComponent as AppStore } from "../Assets/AppStore.svg";
import Hero from "../Components/HeroV2";
import Supporters from "../Components/Supporters";
import Advantages from "../Components/Advantages";
import Faq from "../Components/Faq";
import Menubar from "../Components/Menubar";
import Contact from "../Components/Contact";
import Newsletter from "../Components/Newsletter";
import GetBetaUser from "../Components/GetBetaUser";
import Pricing from "../Components/Pricing";
import HowToStart from "./HowToStart";
import Testamonials from "../Components/Testamonials";
import AudioguideSample from "../Components/AudioguideSample";
import Targetgroup from "../Components/Targetgroup";
import Advantages2 from "../Components/Advantages2";
import Metrics from "../Components/Metrics";
import Additionally from "../Components/Additionally";
import MoreInformation from "../Components/MoreInformation";
import Targetgroup2 from "../Components/Targetgroup2";
import { InlineWidget } from "react-calendly";

function Home() {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const { scrollY } = useScroll();
  const scrollLeftPhone = useTransform(scrollY, [1500, 1800], [-50, 0]);
  const scrollRightPhone = useTransform(scrollY, [120, 1800], [80, 0]);
  const opacityBlob = useTransform(scrollY, [1300, 1460], [0, 1]);

  const opacityCircle = useTransform(scrollY, [900, 1000], [0, 1]);
  const scrollInLeft = useTransform(scrollY, [1400, 1800], [-300, 0]);
  const scrollInTop = useTransform(scrollY, [1400, 1600], [-60, 0]);
  const scrollInRight = useTransform(scrollY, [1400, 1800], [300, 0]);
  const scrollInBottom = useTransform(scrollY, [1400, 1600], [60, 0]);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  return (
    <div className="overflow-hidden">
      <Menubar selected="home" />
      <div className="lg:hidden h-10" />
      <div className="relative z-20">
        <Hero />
      </div>

      <div className="h-[430px] lg:h-[530px] w-screen  bg-gradient-to-b from-[#FCFBFF] to-white " />

      {/* Inform section */}
      <div className="w-full flex lg:mt-0 -mt-10 mb-20 justify-center ">
        <div className="w-full bg-gradient-to-b from-[#FCFBFF] via-[#FCFBFF] to-white border-t border-lightblue pt-20 flex flex-col items-center mt-4 lg:mt-24 ">
          <div className="w-full lg:mt-0 -mt-5 lg:text-center px-5 max-w-6xl lg:mb-20">
            <h2 className="text-3xl text-dark  w-full font-bold ">
              Und so funktioniert’s
            </h2>
            {/* //zzgl MwSt */}
            <p className="text-darkgray   text-lg  lg:mt-5 mt-3">
              In vier einfachen Schritten zum eigenen Multimediaguide
            </p>
          </div>
          <div className="w-full max-w-5xl lg:flex-row flex-col ipad:px-0 px-5 flex lg:mb-20 ">
            <div className="lg:w-1/2 relative lg:pr-20 w-full flex justify-start lg:pt-0 pt-8  lg:z-0 z-20 flex-col">
              <h2 className="font-bold text-3xl mt-8 lg:mt-3 leading-[44px] ">
                1. Inhalte anlegen
              </h2>
              <div className="lg:w-1/2 lg:hidden  w-full lg:mt-0 mt-5 mb-5 ">
                <img
                  style={{ boxShadow: "0px 0px 20px #EFF1F5" }}
                  alt="playStore"
                  src={require("../Assets/hero.png")}
                  className="w-full rounded-lg border-4 border-white"
                />
              </div>
              <p className="text-darkgray lg:flex  text-lg mt-3 lg:mt-5">
                Die Inhalte der Ausstellung im Content-Management-System (CMS)
                selbst anlegen. Hier können Bilder, Texte, Audioguides, Videos,
                GPS-Karten usw. von Ihnen selbst angelegt werden. Verschiedene
                KI’s helfen beispielsweise bei Übersetzungen oder der
                Audioguide-Erstellung. Für die Nutzung ist kein technisches
                Fachwissen notwendig.
              </p>
            </div>
            <div className="lg:w-1/2 hidden lg:flex w-full lg:mt-0 mt-10 ">
              <img
                style={{ boxShadow: "0px 0px 20px #EFF1F5" }}
                alt="playStore"
                src={require("../Assets/hero.png")}
                className="w-full rounded-lg border-4 border-white"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-center ">
        <div className="w-full max-w-5xl justify-center items-center lg:flex-row flex-col ipad:px-0 px-5 flex lg:mb-20 ">
          <h2 className="font-bold lg:hidden text-3xl mb-10 lg:mt-0 leading-[44px] ">
            2. Multimediaguides für Ihre Besucher:innen
          </h2>
          <div className="lg:w-1/3 w-full flex  relative">
            <div className="absolute z-10 w-[700px] hidden lg:flex -left-[220px] -top-[160px] h-[700px]">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill={"#E4EFFF"}
                  d="M43.1,-22.2C57.1,-0.6,70.6,24,63.3,37.4C56.1,50.8,28,53.1,7.5,48.8C-13,44.5,-26,33.5,-35.3,18.9C-44.6,4.3,-50.1,-13.9,-43.6,-31.2C-37.1,-48.5,-18.5,-64.9,-2,-63.8C14.5,-62.6,29.1,-43.9,43.1,-22.2Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
            <div className="absolute z-10 w-[700px] hidden lg:flex rotate-[350deg] -left-[220px] -top-[150px] h-[700px]">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill={"#F7F8FF"}
                  d="M43.1,-22.2C57.1,-0.6,70.6,24,63.3,37.4C56.1,50.8,28,53.1,7.5,48.8C-13,44.5,-26,33.5,-35.3,18.9C-44.6,4.3,-50.1,-13.9,-43.6,-31.2C-37.1,-48.5,-18.5,-64.9,-2,-63.8C14.5,-62.6,29.1,-43.9,43.1,-22.2Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
            <div className="flex justify-center relative">
              <div
                // style={{ opacity: opacityCircle }}
                className="absolute w-full aspect-square rounded-full  bg-secondary border-8 border-[#F6F7FF] -top-5 left-0 lg:hidden hidden"
              />
              <div className="w-5/12 lg:w-1/2 lg:mb-10 z-20 relative">
                <img
                  alt="App Bild"
                  className="w-full"
                  src={require("../Assets/phone3.png")}
                />
              </div>
              <div className="w-5/12 lg:w-1/2 lg:mt-10 z-20  relative lg:ml-5 ml-5 ">
                <img
                  alt="App Bild"
                  className="w-full"
                  src={require("../Assets/phone2.png")}
                />
              </div>
            </div>
          </div>
          <div className="lg:w-2/3 w-full relative flex justify-center lg:pl-40 lg:pt-0 pt-20  lg:z-0 z-20 flex-col">
            <h2 className="font-bold hidden lg:flex text-3xl mt-5 lg:mt-0 leading-[44px] ">
              2. Multimediaguides für Ihre Besucher:innen
            </h2>
            <p className="text-darkgray lg:hidden px-0 flex text-lg -mt-8 lg:mt-5">
              Ihre Besucher:innen mit einem spannenden digitalen Angebot
              begeistern. Alle angelegten Inhalte sind in Echtzeit in der
              mudioo-App oder Web-App verfügbar. Durch das Scannen eines
              QR-Codes gelangen Besucher:innen in wenigen Sekunden zu den von
              Ihnen angelegten Inhalten.
            </p>
            <p className="text-darkgray hidden lg:flex text-center lg:text-left text-lg mt-3 lg:mt-5">
              Ihre Besucher:innen mit einem spannenden digitalen Angebot
              begeistern. Alle angelegten Inhalte sind in Echtzeit in der
              mudioo-App oder Web-App verfügbar. Durch das Scannen eines
              QR-Codes gelangen Besucher:innen in wenigen Sekunden zu den von
              Ihnen angelegten Inhalten.
            </p>

            <div className="flex-row flex rounded-lg lg:bg-none lg:flex-row justify-start lg:justify-start items-center mt-8">
              <a
                target="_blank"
                className="scale-100 lg:scale-100"
                href="https://apps.apple.com/us/app/mudioo/id6452048103"
              >
                <AppStore />
              </a>
              <a
                target="_blank"
                className="ml-3 lg:ml-4"
                href="https://play.google.com/store/apps/details?id=com.mudioo"
              >
                <img
                  alt="playStore"
                  src={require("../Assets/playstore.png")}
                  className="h-[52px] "
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Inform section */}
      <div className="w-full bg-gradient-to-b from-white via-[#FCFBFF] to-white flex flex-col items-center mt-4 lg:mt-24 ">
        <div className="w-full max-w-5xl lg:flex-row flex-col ipad:px-0 px-5 flex lg:mb-20 ">
          <div className="lg:w-8/12 relative pr-22 w-full flex justify-start lg:pt-0 pt-8  lg:z-0 z-20 flex-col">
            <h2 className="font-bold text-3xl mt-8 lg:mt-10 leading-[44px] ">
              3. Besucher:innen informieren
            </h2>
            <p className="text-darkgray lg:flex  text-lg mt-3 lg:mt-5">
              Mit einem Banner, das über die mudioo-App und deren Funktionen
              informiert, gelangen Besucher:innen unkompliziert zur digitalen
              Ausstellung. Zusätzlich generiert die Software für alle Inhalte
              automatisch QR-Codes und Nummern, die in der Ausstellung
              angebracht werden können, um die Besucher:innen zu führen.
            </p>
            {/* <div className="absolute  h-20 -rotate-[65deg] top-44 -right-12">
              <img
                alt="mudioo Logo"
                src={require("../Assets/arrow.png")}
                className="h-full"
              />
            </div> */}

            <h2 className="font-bold text-3xl mt-20 lg:mt-16 leading-[44px] ">
              4. Langfristige Nutzung
            </h2>
            <p className="text-darkgray lg:text-left text-lg mt-3 lg:mt-5">
              Die Software wird vom mudioo-Team laufend auf dem neuesten
              technischen Stand gehalten und immer wenn Sie Fragen haben, hilft
              Ihnen das Support-Team. Da Sie alle Inhalte über das CMS selbst
              verwalten, können Sie diese jederzeit anpassen oder erneuern.
            </p>
          </div>

          <div className="lg:w-4/12 w-full flex hidden lg:block  relative">
            <img
              alt="playStore"
              src={require("../Assets/banner.png")}
              className="w-full rounded-lg pr-16 ml-24"
            />
          </div>
        </div>
      </div>

      {/* <div className="w-full flex flex-col items-center mt-0 ">
        <div className="w-full max-w-5xl lg:flex-row flex-col ipad:px-0 px-5 flex lg:mb-20 ">
          <div className="lg:w-1/3 w-full flex  relative">
            <img
              alt="playStore"
              src={require("../Assets/banner.png")}
              className="w-full rounded-lg"
            />
          </div>

          <div className="lg:w-2/3 pl-28 w-full flex justify-center lg:pt-0 pt-8  lg:z-0 z-20 flex-col">
            <h2 className="font-bold lg:text-left text-center text-3xl mt-8 lg:mt-0 leading-[44px] ">
              4. Langfristige Nutzung
            </h2>
            <p className="text-darkgray lg:flex text-center lg:text-left text-lg mt-3 lg:mt-5">
              Die Software wird vom mudioo-Team laufend auf dem neuesten
              technischen Stand gehalten und immer wenn Sie Fragen haben hilft
              Ihnen das Support-Team. Da Sie alle Inhalte über das CMS selbst
              verwalten, können Sie diese jederzeit anpassen oder erneuern.
            </p>
          </div>
        </div> 
      </div>*/}

      {/* <Targetgroup /> */}

      <Targetgroup />

      <Advantages />

      <Advantages2 />

      <AudioguideSample />

      <Metrics />

      {/* <Additionally /> */}

      <Testamonials />

      <HowToStart />

      <Pricing />

      <Supporters />
      {/* <Newsletter /> */}

      <GetBetaUser />

      <Faq
        faq={[
          {
            question: "Wie funktioniert mudioo?",
            answer:
              "Zunächst pflegen Sie Ihre Inhalte in das Content-Management-System ein. Diese sind dann in Echtzeit in der mudioo-App verfügbar und können von Ihren Besucher:innen genutzt werden. Weiterhin erhalten Sie ein individuelles Banner, welches Ihre Besucher:innen über die mudioo-App informiert und deren Funktion erklärt. Für einen reibungslosen und langfristigen Einsatz der Software kann jederzeit die Hilfe vom mudioo-Support in Anspruch genommen werden und die Software wird laufend auf aktuellem technischen Stand gehalten.",
          },
          {
            question: "Was kostet mudioo?",
            answer: (
              <>
                Für die Basisversion von mudioo erheben wir im Jahresabo einen
                monatlichen Kostenbeitrag von 139€ pro Monat bzw. 1600€ pro
                Jahr. Wenn Sie dies als kleinere Institution nicht leisten
                können,{" "}
                <a className="text-primary underline font-bold" href="/contact">
                  kontaktieren
                </a>{" "}
                Sie uns bitte, dann ermöglichen wir eine solidarische
                Preisanpassung.
                <br />
                <a className="text-primary underline font-bold" href="/pricing">
                  Zur Preisübersicht
                </a>
                <br />
                <span className="text-darkgray text-xs mt-5">
                  zzgl. 19% MwSt.
                </span>
              </>
            ),
          },
          {
            question: "Warum ist mudioo immer zeitlich begrenzt?",
            answer: (
              <>
                Wir wollen Ihnen eine möglichst einfache und sorgenfreie Nutzung
                der mudioo Software ermöglichen. Dazu ist es notwendig, dass Sie
                umfangreiche Support-Leistungen erhalten und, dass die Software
                von uns immer auf dem aktuellen technischen Stand gehalten wird.
                Dies wird durch die laufenden Software-Kosten garantiert.
                <br />
                <br />
                Weiterhin möchten wir so die Investitionskosten so gering wie
                möglich halten. Denn dank diesem Preismodell eliminieren wir
                hohe Anfangsinvestitionen.
                <br />
                <br />
                Zuletzt soll die Software auch nur so lange Kosten verursachen,
                wie Sie auch sinnvoll eingesetzt wird. Anders als bei
                hochpreisiger Individualsoftware haben Sie mit mudioo die
                Möglichkeit, flexibel auf Umstände zu reagieren.
              </>
            ),
          },
          {
            question:
              "Was ist, wenn ich eigentlich weder Zeit noch Personal für die Integration von mudioo habe?",
            answer:
              "Die Nutzung von mudioo ist unkompliziert und sehr schnell zu erlernen. Unsere Erfahrungen zeigen, dass die meisten nötigen Informationen für die jeweiligen Ausstellungen schon vorhanden sind und lediglich in den Content-Manager eingefügt werden müssen. Für den Feinschliff sorgen die verschiedenen unterstützenden Funktionen von mudioo. Bisher hat es durchschnittlich 26 Minuten gedauert bis alle Mitarbeitenden reibungslos mit der mudioo-Software arbeiten konnten. Die Arbeitszeit, die seitens Ihrer Kolleg:innen bisher notwendig war, um mit mudioo einen geeigneten Multimediaguide zu erstellen, betrug durchschnittlich 5 Stunden und 38 Minuten.",
          },
          {
            question: "Bekomme ich Hilfe bei der Einrichtung von mudioo?",
            answer:
              "Die mudioo-Software ist darauf ausgelegt, dass sie ohne jegliches technisches Fachwissen genutzt werden kann. Deswegen können alle Inhalte zunächst einmal selbstständig erstellt werden. Bevor Sie jedoch anfangen mit der Software zu arbeiten, werden Sie von uns vollständig über alle Funktionen aufgeklärt, sodass Sie problemlos eigenständig mit der mudioo-Software arbeiten können. Sollten dann im laufenden Betrieb wieder Fragen bestehen, hilft Ihnen der mudioo-Support. Bisher hat es im Durchschnitt 26 Minuten gedauert bis alle Mitarbeitenden reibungslos mit der mudioo-Software Arbeiten konnten. Die Arbeitszeit, die seitens Ihrer Kolleg:innen bisher notwendig war, um mit mudioo einen Multimediaguide zu erstellen, betrug Durchschnitt 5 Stunden und 30 Minuten.",
          },
          {
            question:
              "Wir haben eine weitläufige Außenausstellung. Können wir mudioo nutzen?",
            answer:
              "Ja, für jedes Exponat kann ein genauer GPS-Standort angegeben werden. Besucher:innen können sich dann über mudioo App navigieren lassen. In der mudioo-App wird dann automatisch eine Karte erstellt, Besucher:innen sehen Ihren eigenen Standort und können sich navigieren lassen.",
          },

          {
            question:
              "Was ist, wenn wir weder WLAN noch Empfang in der Ausstellung haben?",
            answer:
              "Das ist kein Problem. Alle Inhalte können in der mudioo-App heruntergeladen werden, um diese auch offline zu nutzen. Dabei werden alle Inhalte so komprimiert, dass wenig Datenvolumen benötigt wird.",
          },
        ]}
      />

      {/* <div className="w-full flex bg-[#FCFBFF]  relative to-white  pb-28 border-white pt-0 flex-col items-center">
        <div className=" max-w-5xl ipad:px-0 px-5 mt-20 flex lg:flex-row flex-col items-center justify-between w-full  ">
          <div className="w-full lg:w-3/5">
            <h2 className="font-bold lg:text-left text-center  text-4xl mb-5 leading-[45px]">
              Gemeinsam die kulturelle
              <br /> Zukunft gestalten
            </h2>

            <p className="text-lg text-darkgray text-center lg:text-left lg:w-auto w-full  mr-36">
              Mit mudioo möchten wir das Wissen und das kulturelle Erbe unserer
              Gesellschaft für aktuelle, junge und zukünftige Generationen
              aufbereiten und in spannende digitale Vermittlungskonzepte
              überführen. So sollen auch kleinere Museen und Ausstellungen die
              Möglichkeit bekommen, Ihre Inhalte digital und zukunftsorientiert
              zu vermitteln.
              <div className="mt-5 text-primary  font-semibold italic text-sm ">
                Christoph von Trotha; Max Löhrer
              </div>
            </p>
          </div>

          <div className="lg:w-2/5 h-auto lg:h-[400px] w-full acpect-square lg:mt-0 mt-10 relative z-30 aspect-square shadow-xl overflow-hidden rounded-xl">
            <img
              alt="mudioo Team"
              className="object-cover h-full "
              src={require("../Assets/Team.jpg")}
            />
          </div>
        </div>
      </div> */}

      {/* <MoreInformation /> */}

      <div className="w-full flex justify-center">
        <div className="w-full relative flex flex-col pt-10 lg:pt-28 lg:px-0 px-5 pb-12 max-w-5xl">
          <h2 className="text-3xl shrink-0 text-dark lg:text-center font-bold ">
            Lust, mudioo kostenlos auszuprobieren?
          </h2>
          <p className="text-darkgray lg:text-lg relative  lg:text-center mt-4 mb-10 lg:-mb-6 z-30">
            Jetzt ein unverbindliches Infogespräch buchen.
            <br /> Wir schalten Ihnen gerne einen kostenlosen Testaccount frei.
          </p>
          <div className="border border-border lg:border-0">
            <InlineWidget url="https://calendly.com/christoph-v-trotha/-mudioo" />
          </div>
        </div>
      </div>

      <Contact type="Kontakt" bgmode={true} />
    </div>
  );
}

export default Home;
