import React from "react";

function Footer() {
  return (
    <>
      <div className="flex z-30 relative items-center ipad:px-0 px-5 lg:mt-0 mt-0 flex-col w-full bg-dark border-t border-[#163155]">
        <div className="w-full bg-dark flex justify-center items-center   pt-10 pb-0">
          <a
            className="bg-primary rounded-full py-3.5 px-10 text-white"
            target="_blank"
            href="https://calendly.com/christoph-v-trotha/-mudioo"
          >
            Infogespräch buchen
          </a>
        </div>
        <div className="w-full max-w-5xl gap-10 text-white pt-10 lg:pt-16 pb-10 lg:grid lg:grid-cols-4">
          <div>
            <div className="flex w-full justify-center lg:justify-start items-center text-lg font-nunito font-bold">
              <img
                alt="mudioo Logo"
                src={require("../Assets/logo_blue.png")}
                className="mr-3 w-12 lg:w-10"
              />
              <span className="mt-1 text-3xl lg:text-2xl">mudioo</span>
            </div>
            <p className="mt-10 lg:block hidden ">
              Der Multimediaguide für kleine Museen und Aussteller.
            </p>
          </div>
          <div className="hidden lg:flex justify-center">
            <div className="flex flex-col">
              <h1 className="text-xl font-semibold mb-5 text-white">Seiten</h1>
              <a className="text-base mb-2" href="/">
                Startseite
              </a>
              <a className="text-base mb-2" href="/getbeta">
                Demo anfordern
              </a>
              <a className=" text-base mb-2" href="/contact">
                Kontakt aufnehmen
              </a>
            </div>
          </div>

          <div className="flex text-center lg:text-left lg:mt-0 mt-6 justify-center">
            <div className="flex flex-col">
              <h1 className="text-xl lg:block hidden font-semibold mb-5 text-white">
                Rechtliches
              </h1>
              <a
                className="text-lg lg:text-base  font-bold lg:font-medium lg:mt-0 mt-3 mb-2"
                href="/datapolicy"
              >
                Datenschutz
              </a>
              <a
                className="text-lg lg:text-base font-bold lg:font-medium  lg:mt-0 mt-3 mb-2"
                href="/imprint"
              >
                Impressum
              </a>

              <a
                className="text-lg lg:text-base font-bold lg:font-medium  lg:mt-0 mt-3 mb-2"
                href="/terms_of_use"
              >
                Nutzungsbedingungen
              </a>
            </div>
          </div>

          <div className="flex flex-col items-center lg:items-end lg:mt-0 mt-4 ">
            <div className="flex flex-col lg:items-start items-center">
              <h1 className="text-xl lg:block hidden font-semibold mb-5 text-white">
                Kontakt
              </h1>
              <div className="flex items-center ">
                <div className="hidden lg:flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      stroke="white"
                      d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                    />
                  </svg>
                </div>
                <a
                  href="tel:+4915789277139"
                  className="lg:ml-3 text-lightblue lg:text-white font-bold text-base"
                >
                  +49 157 89277139
                </a>
              </div>

              <div className="flex  mt-4 lg:mt-2 items-center ">
                <div className="hidden lg:flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      stroke="white"
                      strokeLinejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                  </svg>
                </div>
                <a
                  href="mailto:hallo@mudioo.de"
                  className="lg:ml-3 text-lightblue lg:text-white font-bold text-base"
                >
                  hallo@mudioo.de
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="text-lightgray text-base py-7 border-t border-[#031f4f] w-full max-w-5xl flex justify-center">
          Copyright © {new Date().getFullYear().toString()} mudioo. All rights
          reserved.
        </div>

        {/* <div className="w-full  max-w-5xl text-white py-6 flex lg:flex-row flex-col-reverse justify-between items-center  lg:justify-between">
          <div>
            <div className="flex bg-red items-center text-lg font-nunito font-bold">
              <img
                alt="mudioo Logo"
                src={require("../Assets/logo_white.png")}
                className="mr-3 w-8"
              />
              <span className="mt-1">mudioo</span>
            </div>
            Ihr Partner für digitale
            <br /> Anwendungen für Ihre Ausstellungen
          </div>

          <div className="lg:ml-36 flex font-bold">
            Made with
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#e05046"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 mx-2"
            >
              <path
                stroke="#e05046"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
              />
            </svg>
            in Kiel
          </div>

          <div className="flex lg:flex-row flex-col  items-center ">
            <Link className="lg:mt-0 mt-0 font-semibold" to="/impressum">
              Impressum
            </Link>
            <Link
              className="lg:mt-0 lg:mt-0 mt-3 font-semibold lg:ml-6"
              to="/Datenschutz"
            >
              Datenschutzerklärung
            </Link>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Footer;
