import React, { useState } from "react";

function Faq({ faq }) {
  return (
    <div className="bg-gradient-to-b from-white to-[#FCFBFF] px-6 lg:px-16 lg:pb-10 pb-10 lg:pt-36 pt-20 w-full flex flex-col items-center ">
      <div className="w-full flex flex-col max-w-5xl relative ">
        <div className="relative text-left">
          <h2 className="text-3xl text-dark font-bold ">
            Häufig gestellte Fragen
          </h2>
        </div>

        <div className="w-full mt-6 ">
          {faq.map((item) => {
            return (
              <div key={item.question}>
                <FaqItem question={item.question} answer={item.answer} />
              </div>
            );
          })}

          <div className="bg-[#FCFBFF] w-full h-1 -mt-1"></div>
        </div>
      </div>
    </div>
  );
}

function FaqItem({ question, answer, className }) {
  const [showAnswer, setShowAnswer] = useState(false);

  return (
    <div
      onClick={() => setShowAnswer(!showAnswer)}
      className={` border-b-2  border-border w-full py-5  cursor-pointer ${className}`}
    >
      <div className="flex items-center justify-between">
        <div
          className={`text-xl text-dark font-bold ${
            showAnswer ? "text-dark" : "text-dark"
          }`}
        >
          {question}
        </div>

        <div className="rounded-full flex items-center transition-all justify-center mr-3  w-7 h-7">
          <div className={`transition-all   ${showAnswer ? "rotate-45" : ""}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </div>
        </div>
      </div>
      {showAnswer && (
        <div
          className={`pl-0 p-3 text-lg text-dark overflow-hidden transition-all`}
        >
          {answer}
        </div>
      )}
    </div>
  );
}

export default Faq;
