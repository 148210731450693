import React from "react";
import Menubar from "../Components/Menubar";

function AppDatapolicy() {
  return (
    <div className="overflow-hidden ">
      <Menubar />

      <div className="bg-gradient-to-b from-[#FCFBFF] py-36 px-5 lg:px-0 to-[#FCFBFF] flex justify-center w-full ">
        <div className="h-32" />
        <div className="w-full max-w-5xl">
          <h1 className="font-bold text-3xl lg:text-5xl max-w-4xl lg:mt-10 text-text leading-[40px]  lg:leading-[65px]">
            Datenschutzerklärung
          </h1>
          <p className="mt-8 leading-[28px]">
            Diese Datenschutzinformationen geltend für die Nutzung der
            mudioo-App und des mudioo-Content-Management-Systems (nachfolgend:
            „mudioo-cms“; mudioo-App und mudioo-cms zusammen nachfolgend:
            „mudioo-Plattform“).
          </p>
          <h1 className="font-bold mt-8 text-xl">A. Allgemeines</h1>
          <h2 className="mt-4 italic font-semibold">1. Begriffsbestimmungen</h2>

          <p className="mt-2 leading-[28px]">
            Nach dem Vorbild des Art. 4 DS-GVO liegen dieser Datenschutzhinweise
            folgende Begriffsbestimmungen zugrunde:
          </p>

          <div className="flex pt-2">
            <p>-</p>
            <p className="leading-[28px] ml-3">
              "Personenbezogene Daten" ( Art. 4 Nr. 1 DS-GVO) sind alle
              Informationen, die sich auf eine identifizierte oder
              identifizierbare natürliche Person ("Betroffener") beziehen.
              Identifizierbar ist eine Person, wenn sie direkt oder indirekt,
              insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
              einer Kennnummer, einer Online-Kennung, Standortdaten oder
              mithilfe von Informationen zu ihren physischen, physiologischen,
              genetischen, psychischen, wirtschaftlichen, kulturellen oder
              sozialen Identitätsmerkmalen identifiziert werden kann. Die
              Identifizierbarkeit kann auch mittels einer Verknüpfung von
              derartigen Informationen oder anderem Zusatzwissen gegeben sein.
              Auf das Zustandekommen, die Form oder die Verkörperung der
              Informationen kommt es nicht an (auch Fotos, Video- oder
              Tonaufnahmen können personenbezogene Daten enthalten).
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              "Verarbeiten" ( Art. 4 Nr. 2 DS-GVO) ist jeder Vorgang, bei dem
              mit personenbezogenen Daten umgegangen wird, gleich ob mit oder
              ohne Hilfe automatisierter (dh technikgestützter) Verfahren. Dies
              umfasst insbesondere das Erheben (dh die Beschaffung), das
              Erfassen, die Organisation, das Ordnen, die Speicherung, die
              Anpassung oder Veränderung, das Auslesen, das Abfragen, die
              Verwendung, die Offenlegung durch Übermittlung, die Verbreitung
              oder sonstige Bereitstellung, den Abgleich, die Verknüpfung, die
              Einschränkung, das Löschen oder die Vernichtung von
              personenbezogenen Daten sowie die Änderung einer Ziel- oder
              Zweckbestimmung, die einer Datenverarbeitung ursprünglich zugrunde
              gelegt wurde.
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              "Verantwortlicher" ( Art. 4 Nr. 7 DS-GVO) ist die natürliche oder
              juristische Person, Behörde, Einrichtung oder andere Stelle, die
              allein oder gemeinsam mit anderen über die Zwecke und Mittel der
              Verarbeitung von personenbezogenen Daten entscheidet.
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              "Dritter" ( Art. 4 Nr. 10 DS-GVO) ist jede natürliche oder
              juristische Person, Behörde, Einrichtung oder andere Stelle außer
              dem Betroffenen, dem Verantwortlichen, dem Auftragsverarbeiter und
              den Personen, die unter der unmittelbaren Verantwortung des
              Verantwortlichen oder Auftragsverarbeiters befugt sind, die
              personenbezogenen Daten zu verarbeiten; dazu gehören auch andere
              konzernangehörige juristische Personen.
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              "Auftragsverarbeiter" ( Art. 4 Nr. 8 DS-GVO) ist eine natürliche
              oder juristische Person, Behörde, Einrichtung oder andere Stelle,
              die personenbezogene Daten im Auftrag des Verantwortlichen,
              insbesondere gemäß dessen Weisungen, verarbeitet (zB
              IT-Dienstleister). Im datenschutzrechtlichen Sinne ist ein
              Auftragsverarbeiter insbesondere kein Dritter.
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              "Einwilligung" ( Art. 4 Nr. 11 DS-GVO) der betroffenen Person
              bezeichnet jede freiwillig für den bestimmten Fall, in
              informierter Weise und unmissverständlich abgegebene
              Willensbekundung in Form einer Erklärung oder einer sonstigen
              eindeutigen bestätigenden Handlung, mit der die betroffene Person
              zu verstehen gibt, dass sie mit der Verarbeitung der sie
              betreffenden personenbezogenen Daten einverstanden ist.
            </p>
          </div>

          <h2 className="mt-4 italic font-semibold">
            2. Änderung der Datenschutzhinweise{" "}
          </h2>

          <p className="mt-2 leading-[28px]">
            (1) Im Rahmen der Fortentwicklung des Datenschutzrechts sowie
            technologischer oder organisatorischer Veränderungen werden unsere
            Datenschutzhinweise regelmäßig auf Anpassungs- oder Ergänzungsbedarf
            hin überprüft. Über Änderungen werden Sie unterrichtet.
            <div className="h-2" />
            (2) Diese Datenschutzhinweise haben den Stand von November 2023.
          </p>

          <h2 className="mt-4 italic font-semibold">
            3. Keine Verpflichtung zur Bereitstellung personenbezogener Daten
          </h2>

          <p className="mt-2 leading-[28px]">
            Wir machen den Abschluss von Verträgen mit uns nicht davon abhängig,
            dass Sie uns zuvor personenbezogene Daten bereitstellen. Für Sie als
            Kunde besteht grundsätzlich auch keine gesetzliche oder vertragliche
            Verpflichtung, uns Ihre personenbezogenen Daten zur Verfügung zu
            stellen; es kann jedoch sein, dass wir bestimmte Angebote nur
            eingeschränkt oder gar nicht erbringen können, wenn Sie die dafür
            erforderlichen Daten nicht bereitstellen. Sofern dies im Rahmen der
            nachfolgend vorgestellten, von uns angebotenen Produkte
            ausnahmsweise der Fall sein sollte, werden Sie gesondert darauf
            hingewiesen.
          </p>

          <h1 className="font-bold mt-8 text-xl">
            B. Informationen über die Verarbeitung Ihrer Daten
          </h1>
          <h2 className="mt-4 italic font-semibold">
            1. Die Erhebung Sie betreffender personenbezogener Daten
          </h2>

          <p className="mt-2 leading-[28px]">
            (1) Bei der Nutzung unserer App werden von uns personenbezogene
            Daten über Sie erhoben.
            <div className="h-2" />
            (2) Personenbezogene Daten sind alle Daten, die sich auf Ihre Person
            beziehen (siehe oben unter Allgemeines). Beispielsweise handelt es
            sich bei Ihrem Namen, Ihrer Standortdaten, Ihre IP-Adresse, die
            Gerätekennung, die SIM-Kartennummer, Ihrer Adresse sowie
            E-Mail-Adresse um personenbezogene Daten, Ihr Fingerabdruck, Bilder,
            Filme, Audioaufnahmen, aber auch Ihr Nutzerverhalten fällt in diese
            Kategorie.
          </p>

          <h2 className="mt-4 italic font-semibold">
            2. Rechtsgrundlagen der Datenverarbeitung
          </h2>

          <p className="mt-2 leading-[28px]">
            (1) Von Gesetzes wegen ist im Grundsatz jede Verarbeitung
            personenbezogener Daten verboten und nur dann erlaubt, wenn die
            Datenverarbeitung unter einen der folgenden
            Rechtfertigungstatbestände fällt:
          </p>
          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              Art. 6 Abs. 1 S. 1 lit. a DS-GVO ("Einwilligung"): Wenn der
              Betroffene freiwillig, in informierter Weise und
              unmissverständlich durch eine Erklärung oder eine sonstige
              eindeutige bestätigende Handlung zu verstehen gegeben hat, dass er
              mit der Verarbeitung der ihn betreffenden personenbezogenen Daten
              für einen oder mehrere bestimmte Zwecke einverstanden ist;
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              Art. 6 Abs. 1 S. 1 lit. b DS-GVO: Wenn die Verarbeitung zur
              Erfüllung eines Vertrags, dessen Vertragspartei der Betroffene
              ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich
              ist, die auf die Anfrage des Betroffenen erfolgen;
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              Art. 6 Abs. 1 S. 1 lit. c DS-GVO: Wenn die Verarbeitung zur
              Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der
              der Verantwortliche unterliegt (zB eine gesetzliche
              Aufbewahrungspflicht);
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              Art. 5 Abs. 1 S. 1 lit. d DS-GVO: Wenn die Verarbeitung
              erforderlich ist, um lebenswichtige Interessen des Betroffenen
              oder einer anderen natürlichen Person zu schützen;
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              Art. 6 Abs. 1 S. 1 lit. e DS-GVO: Wenn die Verarbeitung für die
              Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen
              Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die
              dem Verantwortlichen übertragen wurde oder
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              Art. 6 Abs. 1 S. 1 lit. f DS-GVO ("Berechtigte Interessen"): Wenn
              die Verarbeitung zur Wahrung berechtigter (insbesondere
              rechtlicher oder wirtschaftlicher) Interessen des Verantwortlichen
              oder eines Dritten erforderlich ist, sofern nicht die
              gegenläufigen Interessen oder Rechte des Betroffenen überwiegen
              (insbesondere dann, wenn es sich dabei um einen Minderjährigen
              handelt).
            </p>
          </div>

          <p className="mt-2 leading-[28px]">
            Die Speicherung von Informationen in der Endeinrichtung des
            Endnutzers oder der Zugriff auf Informationen, die bereits in der
            Endeinrichtung gespeichert sind, sind nur zulässig, wenn sie von
            einem der folgenden Rechtfertigungstatbeständen gedeckt sind:
          </p>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              § 25 Abs. 1 TTDSG: Wenn der Endnutzer auf der Grundlage von klaren
              und umfassenden Informationen eingewilligt hat. Die Einwilligung
              hat gem. Art. 6 Abs. 1 S. 1 lit. a DS-GVO zu erfolgen;
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              § 25 Abs. 2 Nr. 1 TTDSG: Wenn der alleinige Zweck die Durchführung
              der Übertragung einer Nachricht über ein öffentliches
              Telekommunikationsnetz ist oder
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              § 25 Abs. 2 Nr. 2 TTDSG: Wenn die Speicherung oder der Zugriff
              unbedingt erforderlich ist, damit der Anbieter eines
              Telemediendienstes einen vom Nutzer ausdrücklich gewünschten
              Telemediendienst zur Verfügung stellen kann.
            </p>
          </div>

          <p className="mt-2 leading-[28px]">
            (2) Für die von uns vorgenommenen Verarbeitungsvorgänge geben wir im
            Folgenden jeweils die anwendbare Rechtsgrundlage an. Eine
            Verarbeitung kann auch auf mehreren Rechtsgrundlagen beruhen.
          </p>

          <h2 className="mt-4 italic font-semibold">
            3. Die beim Download erhobenen Daten
          </h2>

          <p className="mt-2 leading-[28px]">
            (1) Beim Download dieser App werden bestimmte dafür erforderlichen
            Daten zu Ihrer Person an den entsprechenden App Store (zB Apple App
            Store oder Google Play) übermittelt.
            <div className="h-2" />
            (2) Insbesondere werden beim Herunterladen die E-Mail-Adresse, der
            Nutzername, die Kundennummer des herunterladenden Accounts, die
            individuelle Gerätekennziffer, Zahlungsinformationen sowie der
            Zeitpunkt des Downloads an den App Store übertragen werden.
            <div className="h-2" />
            (3) Auf die Erhebung und Verarbeitung dieser Daten haben wir keinen
            Einfluss, sie erfolgt vielmehr ausschließlich durch den von Ihnen
            ausgewählten App Store. Dementsprechend sind wir für diese Erhebung
            und Verarbeitung nicht verantwortlich; die Verantwortung dafür liegt
            allein beim App Store.
          </p>

          <h2 className="mt-4 italic font-semibold">
            4. Bei der Nutzung erhobenen Daten
          </h2>

          <p className="mt-2 leading-[28px]">
            (1) Die Vorzüge unserer App können wir ihnen zwangsläufig nur zur
            Verfügung stellen, wenn bei der Nutzung von uns bestimmte, für den
            App-Betrieb erforderliche Daten zu Ihrer Person erhoben werden.
            <div className="h-2" />
            (2) Wir erheben diese Daten nur, wenn dies für die Erfüllung des
            Vertrags zwischen Ihnen und uns erforderlich ist ( Art. 6 Abs. 1
            lit. b DS-GVO). Ferner erheben wir diese Daten, wenn dies für die
            Funktionsfähigkeit der App erforderlich ist und Ihr Interesse am
            Schutz Ihrer personenbezogenen Daten nicht überwiegt ( Art. 6 Abs. 1
            lit. f DS-GVO) oder wenn Sie in die Erhebung und Verarbeitung
            einwilligen ( Art. 6 Abs. 1 lit. a DS-GVO).
            <div className="h-2" />
            (3) Wir erheben und verarbeiten folgende Daten von Ihnen:
          </p>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              Geräteinformationen: Zu den Zugriffsdaten gehören die IP-Adresse,
              Geräte-ID, Geräteart, gerätespezifische Einstellungen und
              App-Einstellungen sowie App-Eigenschaften, das Datum und die
              Uhrzeit des Abrufs, Zeitzone die übertragene Datenmenge und die
              Meldung, ob der Datenaustausch vollständig war, Absturz der App,
              Browserart und Betriebssystem. Diese Zugriffsdaten werden
              verarbeitet, um den Betrieb der App technischen zu ermöglichen
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              Daten, die Sie uns zur Verfügung stellen: Soweit für die Nutzung
              der App die Erstellung eines Nutzerkontos erforderlich ist, geben
              Sie mindestens Ihre E-Mailadresse an.
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              Standortdaten: Um die Nutzung der App als Guide in Museen u.ä zu
              ermöglichen, verarbeitet die App die Standortdaten der Nutzer.
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              Informationen mit Ihrer Einwilligung: Sonstige Informationen
              (Tracking des Nutzerverhaltens und Erstellung von Auswertungen für
              Museumsbetreiber, Tracking des Nutzerverhaltens der Nutzer des
              Content-Management-Systems) verarbeiten wir, wenn Sie uns dies
              gestatten.
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              Kontaktformulardaten: Bei der Nutzung von Kontaktformularen werden
              die dadurch übermittelten Daten verarbeitet (zB Geschlecht, Name
              und Vorname, Anschrift, Firma, E-Mail-Adresse und der Zeitpunkt
              der Übermittlung).
            </p>
          </div>

          <p className="mt-2 leading-[28px]">
            (4) Sofern für die Verarbeitung der Daten die Speicherung von
            Informationen in Ihrer Endeinrichtung oder der Zugriff auf
            Informationen, die bereits in der Endeinrichtung gespeichert sind,
            erforderlich ist, ist § 25 Abs. 1 , 2 TTDSG hierfür die
            Rechtsgrundlage.
          </p>

          <h2 className="mt-4 italic font-semibold">5. Einsatz von Cookies</h2>

          <p className="mt-2 leading-[28px]">
            (1) Beim Betrieb unserer App nutzen wir Cookies. Bei Cookies handelt
            es sich um kleine Textdateien, die auf dem Gerätespeicher Ihres
            mobilen Endgerätes abgelegt und der von Ihnen verwendeten mobilen
            App zugeordnet gespeichert werden und durch welche der Stelle, die
            das Cookie setzt, bestimmte Informationen zufließen. Cookies können
            keine Programme ausführen oder Viren auf Ihren Computer übertragen
            und daher keine Schäden anrichten. Sie dienen dazu, unsere App
            insgesamt nutzerfreundlicher und effektiver, also für Sie angenehmer
            zu machen.
            <div className="h-2" />
            (2) Cookies können Daten enthalten, die eine Wiedererkennung des
            genutzten Geräts möglich machen. Teilweise enthalten Cookies aber
            auch lediglich Informationen zu bestimmten Einstellungen, die nicht
            personenbeziehbar sind. Cookies können einen Nutzer aber nicht
            direkt identifizieren.
            <div className="h-2" />
            (3) Man unterscheidet zwischen Session-Cookies, die wieder gelöscht
            werden, sobald Sie ihren Browser schließen und permanenten Cookies,
            die über die einzelne Sitzung hinaus gespeichert werden.
            Hinsichtlich ihrer Funktion unterscheidet man bei Cookies wiederum
            zwischen:
          </p>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              Technical Cookies: Diese sind zwingend erforderlich, um sich
              innerhalb der App zu bewegen, grundlegende Funktionen zu nutzen
              und die Sicherheit der App zu gewährleisten; sie sammeln weder
              Informationen über Sie zu Marketingzwecken noch speichern sie,
              welche Webseiten Sie besucht haben;
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              Performance Cookies: Diese sammeln Informationen darüber, wie Sie
              unsere App nutzen, welche Seiten Sie besuchen und zB ob Fehler bei
              der Appnutzung auftreten; sie sammeln keine Informationen, die Sie
              identifizieren könnten – alle gesammelten Informationen sind
              anonym und werden nur verwendet, um unsere App zu verbessern und
              herauszufinden, was unsere Nutzer interessiert;
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              Advertising Cookies, Targeting Cookies: Diese dienen dazu, dem
              Appnutzer bedarfsgerechte Werbung innerhalb der App oder Angebote
              von Dritten anzubieten und die Effektivität dieser Angebote zu
              messen; Advertising und Targeting Cookies werden maximal 13 Monate
              lang gespeichert;
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              Sharing Cookies: Diese dienen dazu, die Interaktivität unserer App
              mit anderen Diensten (zB sozialen Netzwerken) zu verbessern;
              Sharing Cookies werden maximal 13 Monate lang gespeichert.
            </p>
          </div>

          <p className="mt-2 leading-[28px]">
            (4) Rechtsgrundlage für Cookies, die unbedingt erforderlich sind, um
            Ihnen den ausdrücklich gewünschten Dienst zur Verfügung zu stellen,
            ist § 25 Abs. 2 Nr. 2 TTDSG.
            <div className="h-2" />
            (5) Jeder Einsatz von Cookies, der nicht zwingend technisch
            erforderlich ist, stellt eine Datenverarbeitung dar, die nur mit
            einer ausdrücklichen und aktiven Einwilligung Ihrerseits gem. § 25
            Abs. 1 TTDSG iVm Art. 6 Abs. 1 S. 1 lit. a DS-GVO erlaubt ist. Dies
            gilt insbesondere für die Verwendung von Performance, Advertising,
            Targeting oder Sharing Cookies. Darüber hinaus geben wir Ihre durch
            Cookies verarbeiteten personenbezogenen Daten nur an Dritte weiter,
            wenn Sie nach Art. 6 Abs. 1 S. 1 lit. a DS-GVO eine ausdrückliche
            Einwilligung dazu erteilt haben.
          </p>

          <h2 className="mt-4 italic font-semibold">6. Cookie-Richtlinie</h2>

          <p className="mt-2 leading-[28px]">
            Weitere Informationen darüber, welche Cookies wir verwenden und wie
            Sie Ihre Cookie-Einstellungen verwalten und bestimmte Arten von
            Tracking deaktivieren können, finden Sie in unserer
            Cookie-Richtlinie.
          </p>

          <h2 className="mt-4 italic font-semibold">
            7. Zeitraum der Datenspeicherung
          </h2>
          <p className="mt-2 leading-[28px]">
            (1) Wir löschen Ihre personenbezogenen Daten, sobald sie für die
            Zwecke, für die wir sie nach den erhoben oder verwendet haben (siehe
            C. 4., 5., 6.), nicht mehr erforderlich sind. In der Regel speichern
            wir Ihre personenbezogenen Daten für die Dauer des Nutzungs- bzw.
            des Vertragsverhältnisses über die App. Eine Speicherung Ihrer Daten
            erfolgt grundsätzlich nur auf unseren Servern in Deutschland,
            vorbehaltlich einer ggf. erfolgenden Weitergabe nach den Regelungen
            in F. 1., 2. und 3.
            <div className="h-2" />
            (2) Eine Speicherung kann jedoch über die angegebene Zeit hinaus im
            Falle einer (drohenden) Rechtsstreitigkeit mit Ihnen oder eines
            sonstigen rechtlichen Verfahrens erfolgen.
            <div className="h-2" />
            (3) Von uns eingesetzte Dritte (siehe F. 1.) werden Ihre Daten auf
            deren System so lange speichern, wie es im Zusammenhang mit der
            Erbringung der Leistung für uns entsprechend dem jeweiligen Auftrag
            erforderlich ist.
            <div className="h-2" />
            (4) Rechtliche Vorgaben zur Aufbewahrung und Löschung
            personenbezogener Daten bleiben von Vorstehendem unberührt (zB § 257
            HGB oder § 147 AO). Wenn die durch die gesetzlichen Vorschriften
            vorgeschriebene Speicherfrist abläuft, erfolgt eine Sperrung oder
            Löschung der personenbezogenen Daten, es sei denn, dass eine weitere
            Speicherung durch uns erforderlich ist und dafür eine
            Rechtsgrundlage besteht.
          </p>

          <h2 className="mt-4 italic font-semibold">8. Datensicherheit</h2>
          <p className="mt-2 leading-[28px]">
            (1) Wir bedienen uns geeigneter technischer und organisatorischer
            Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder
            vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust,
            Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen
            unter Berücksichtigung des Stands der Technik, der
            Implementierungskosten und der Natur, des Umfangs, des Kontextes und
            des Zwecks der Verarbeitung sowie der bestehenden Risiken einer
            Datenpanne (inklusive von deren Wahrscheinlichkeit und Auswirkungen)
            für den Betroffenen. Unsere Sicherheitsmaßnahmen werden entsprechend
            der technologischen Entwicklung fortlaufend verbessert.
            <div className="h-2" />
            (2) Nähere Informationen hierzu erteilen wir Ihnen auf Anfrage
            gerne. Wenden Sie sich hierzu bitte an unseren
            Datenschutzbeauftragten (siehe D. 1.).
          </p>

          <h2 className="mt-4 italic font-semibold">
            9. Keine automatisierte Entscheidungsfindung (einschließlich
            Profiling)
          </h2>
          <p className="mt-2 leading-[28px]">
            Wir haben nicht die Absicht, von Ihnen erhobene personenbezogene
            Daten für ein Verfahren zur automatisierten Entscheidungsfindung
            (einschließlich Profiling) zu verwenden.
          </p>

          <h2 className="mt-4 italic font-semibold">10. Zweckänderung</h2>
          <p className="mt-2 leading-[28px]">
            (1) Verarbeitungen Ihrer personenbezogenen Daten zu anderen als den
            beschriebenen Zwecken erfolgen nur, soweit eine Rechtsvorschrift
            dies erlaubt oder Sie in den geänderten Zweck der Datenverarbeitung
            eingewilligt haben.
            <div className="h-2" />
            (2) Im Falle einer Weiterverarbeitung zu anderen Zwecken als denen,
            für den die Daten ursprünglich erhoben worden sind, informieren wir
            Sie vor der Weiterverarbeitung über diese anderen Zwecke und stellen
            Ihnen sämtliche weitere hierfür maßgeblichen Informationen zur
            Verfügung.
          </p>

          <h1 className="font-bold mt-8 text-xl">
            C. Verantwortlichkeit für Ihre Daten und Kontakte
          </h1>
          <h2 className="mt-4 italic font-semibold">
            1. Verantwortlicher und Kontaktdaten
          </h2>
          <p className="mt-2 leading-[28px]">
            (1) Die für die Verarbeitung Ihrer personenbezogenen Daten
            verantwortliche Stelle im Sinne des Art. 4 Nr. 7 DS-GVO sind wir
            <div className="h-2" />
            <div>
              mudioo UG (haftungsbeschränkt)
              <br />
              Wischhofstraße 1-3
              <br />
              24148 Kiel
              <br />
              <div className="h-2" />
              015789277139
              <br />
              <a className="text-primary" href="mailto:hallo@mudioo.de">
                hallo@mudioo.de
              </a>
              <br />
            </div>
            <div className="h-2" />
            (2) Wenden Sie sich an diese Kontaktstelle insbesondere, wenn Sie
            die Ihnen zustehenden Rechte, die unter Kapitel G erläutert werden,
            uns gegenüber geltend machen wollen.
            <div className="h-2" />
            (3) Bei weiteren Fragen oder Anmerkungen zur Erhebung und
            Verarbeitung Ihrer personenbezogenen Daten wenden Sie sich ebenfalls
            an die vorbenannten Kontakte.
          </p>
          <h2 className="mt-4 italic font-semibold">
            2. Datenerhebung bei der Kontaktaufnahme
          </h2>
          <p className="mt-2 leading-[28px]">
            (1) Wenn Sie mit uns per E-Mail oder über ein Kontaktformular mit
            uns Kontakt aufnehmen, dann werden Ihre E-Mail-Adresse, Ihr Name und
            alle weiteren personenbezogenen Daten, die Sie im Zuge der
            Kontaktaufnahme angegeben haben, von uns gespeichert, damit wir mit
            Ihnen zur Beantwortung der Frage Kontakt aufnehmen können.
            <div className="h-2" />
            (2) Diese Daten löschen wir, sobald die Speicherung nicht mehr
            erforderlich ist. Liegen gesetzliche Aufbewahrungsfristen vor,
            bleiben die Daten zwar gespeichert, aber wir schränken die
            Verarbeitung ein.
          </p>

          <h1 className="font-bold mt-8 text-xl">
            D. Datenverarbeitung durch Dritte
          </h1>

          <h2 className="mt-4 italic font-semibold">
            1. Auftragsdatenverarbeitung
          </h2>

          <p className="mt-2 leading-[28px]">
            (1) Es kann vorkommen, dass für einzelne Funktionen unserer App auf
            beauftragte Dienstleister zurückgegriffen wird. Diese werden nur
            nach unserer Weisung tätig und wurden iSv Art. 28 DS-GVO vertraglich
            dazu verpflichtet, die datenschutzrechtlichen Bestimmungen
            einzuhalten.
            <div className="h-2" />
            (2) Folgende Kategorien von Empfängern, bei denen es sich im
            Regelfall um Auftragsverarbeiter handelt, erhalten ggf. Zugriff auf
            Ihre personenbezogenen Daten:
          </p>
          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              Dienstleister für den Betrieb unserer App und die Verarbeitung der
              durch die Systeme gespeicherten oder übermittelten Daten (zB für
              Rechenzentrumsleistungen, Zahlungsabwicklungen, IT-Sicherheit).
              Rechtsgrundlage für die Weitergabe ist dann Art. 6 Abs. 1 S. 1
              lit. b oder lit. f DS-GVO, soweit es sich nicht um
              Auftragsverarbeiter handelt;
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              Staatliche Stellen/Behörden, soweit dies zur Erfüllung einer
              gesetzlichen Verpflichtung erforderlich ist. Rechtsgrundlage für
              die Weitergabe ist dann Art. 6 Abs. 1 S. 1 lit. c DS-GVO;
            </p>
          </div>

          <div className="flex pt-3">
            <p>-</p>
            <p className="leading-[28px] ml-2">
              Zur Durchführung unseres Geschäftsbetriebs eingesetzte Personen
              (zB Auditoren, Banken, Versicherungen, Rechtsberater,
              Aufsichtsbehörden, Beteiligte bei Unternehmenskäufen oder der
              Gründung von Gemeinschaftsunternehmen). Rechtsgrundlage für die
              Weitergabe ist dann Art. 6 Abs. 1 S. 1 lit. b oder lit. f DS-GVO.
            </p>
          </div>
          <p className="mt-2 leading-[28px]">
            (3) Darüber hinaus geben wir Ihre personenbezogenen Daten nur an
            Dritte weiter, wenn Sie nach Art. 6 Abs. 1 S. 1 lit. a DS-GVO eine
            ausdrückliche Einwilligung dazu erteilt haben.
            <div className="h-2" />
            (4) Sofern personenbezogene Daten von Ihnen durch uns an unsere
            Tochtergesellschaften weitergegeben werden oder von unseren
            Tochtergesellschaften an uns weitergegeben werden (zB zu werblichen
            Zwecken), geschieht dies aufgrund von bestehenden
            Auftragsverarbeitungsverhältnissen.
          </p>

          <h2 className="mt-4 italic font-semibold">
            2. Einsatz von Google Analytics 4 und Google Maps
          </h2>
          <p className="mt-2 leading-[28px]">
            Soweit Sie Ihre Einwilligung erklärt haben, wird in der mudioo-App
            und im mudioo-cms Google Analytics 4 und/oder Google Maps
            eingesetzt. Google Analytics ist ein Webanalysedienst der Google
            LLC. Google Maps ist ein Kartendienst der Google LLC.
            Verantwortliche Stelle für Nutzer in der EU/ dem EWR und der Schweiz
            ist Google Ireland Limited, Google Building Gordon House, 4 Barrow
            St, Dublin, D04 E5W5, Irland („Google“).
          </p>

          <h2 className="font-bold mt-5">Art und Zweck der Verarbeitung</h2>
          <p className="mt-2 leading-[28px]">
            Google Analytics verwendet Cookies, die eine Analyse der Benutzung
            der mudioo-App und des mudioo-cms ermöglichen. Google Maps wird zur
            leichteren Auffindbarkeit der die App benutzenden Einrichtungen
            (z.B. Museen) genutzt. Im Auftrag unserer Kunden wird Google diese
            Informationen benutzen, um Ihre Nutzung der mudioo-App auszuwerten
            und um Reports über die mudioo-App-Aktivitäten zusammenzustellen.
            Die durch Google Analytics bereitgestellten Reports dienen der
            Analyse und Verbesserung des Angebots der Museen und der mudioo-App.
            Die mittels der Cookies erhobenen Informationen über die Benutzung
            der mudioo-App und des mudioo-cms werden in der Regel an einen
            Server von Google in den USA übertragen und dort gespeichert.
            <br />
            <br />
            Bei Google Analytics 4 ist die Anonymisierung von IP-Adressen
            standardmäßig aktiviert. Aufgrund der IP-Anonymisierung wird Ihre
            IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen
            Union oder in anderen Vertragsstaaten des Abkommens über den
            Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die
            volle IP-Adresse an einen Server von Google in den USA übertragen
            und dort gekürzt. Die im Rahmen von Google Analytics von Ihrem
            Browser übermittelte IP-Adresse wird laut Google nicht mit anderen
            Daten von Google zusammengeführt. <br />
            <br />
            Während der Nutzung der mudioo-App wird Ihr Nutzerverhalten erfasst.
            Erfasste Ereignisse können sein: Seitenaufrufe, Erstmaliger Besuch
            der Website, Start der Sitzung, Besuchte Webseiten, Ihr „Klickpfad“,
            Interaktion mit der Website, Scrolls (immer wenn ein Nutzer bis zum
            Seitenende (90%) scrollt), Klicks auf externe Links, interne
            Suchanfragen, Interaktion mit Videos, Dateidownloads, gesehene /
            angeklickte Anzeigen, Spracheinstellung
            <br />
            <br />
            Außerdem wird erfasst:
            <ul class="list-disc ml-5">
              <li>Ihr ungefährer Standort (Region)</li>
              <li>Datum und Uhrzeit des Besuchs</li>
              <li>Ihre IP-Adresse (in gekürzter Form) </li>
              <li>
                technische Informationen zu Ihrem Browser und den von Ihnen
                genutzten Endgeräten (z.B. Spracheinstellung,
                Bildschirmauflösung){" "}
              </li>
              <li>Ihr Internetanbieter </li>
              <li>
                die Referrer-URL (über welche Website/ über welches Werbemittel
                Sie auf diese Website gekommen sind){" "}
              </li>
            </ul>
          </p>

          <h2 className="font-bold mt-5">Empfänger</h2>
          <p className="mt-2 leading-[28px]">
            Empfänger der Daten sind/können sein
          </p>
          <ul class="list-disc ml-5">
            <li>
              Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
              Irland (als Auftragsverarbeiter nach Art. 28 DSGVO){" "}
            </li>
            <li>
              Google LLC, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA{" "}
            </li>
            <li>
              Alphabet Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043,
              USA
            </li>
            <li>Museumsbetreiber</li>
          </ul>
          <h2 className="font-bold mt-5">Drittlandtransfer</h2>

          <p className="mt-2 leading-[28px]">
            Für die USA hat die Europäische Kommission am 10.Juli 2023 ihren
            Angemessenheitsbeschluss angenommen. Google LLC ist nach dem EU-US
            Privacy Framework zertifiziert. Da Google-Server weltweit verteilt
            sind und eine Übertragung in Drittländer (beispielsweise nach
            Singapur) nicht völlig ausgeschlossen werden kann, haben wir mit dem
            Anbieter zudem die{" "}
            <a
              className="text-primary underline font-semibold"
              href="https://business.safety.google/adsprocessorterms/sccs/c2p/"
            >
              EU-Standardvertragsklauseln
            </a>{" "}
            abgeschlossen.
          </p>

          <h2 className="font-bold mt-5">Speicherdauer</h2>

          <p className="mt-2 leading-[28px]">
            Die von uns gesendeten und mit Cookies verknüpften Daten werden nach
            2 Jahren automatisch gelöscht. Die maximale Lebensdauer der Google
            Analytics Cookies beträgt 2 Jahre. Die Löschung von Daten, deren
            Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im
            Monat.
          </p>

          <h2 className="font-bold mt-5">Rechtsgrundlage</h2>

          <p className="mt-2 leading-[28px]">
            Rechtsgrundlage für diese Datenverarbeitung ist Ihre Einwilligung
            gem. Art. 6 Abs.1 S.1 lit.a DSGVO und § 25 Abs. 1 S.1 TTDSG.
          </p>

          <h2 className="font-bold mt-5">Widerruf</h2>

          <p className="mt-2 leading-[28px]">
            Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft
            widerrufen, indem Sie die Cookie-Einstellungen aufrufen und dort
            Ihre Auswahl ändern. Die Rechtmäßigkeit der aufgrund der
            Einwilligung bis zum Widerruf erfolgten Verarbeitung bleibt davon
            unberührt.
          </p>
          <p className="mt-2 leading-[28px]">
            Sie können die Speicherung von Cookies auch von vornherein durch
            eine entsprechende Einstellung Ihrer Browser-Software verhindern.
            Wenn Sie Ihren Browser so konfigurieren, dass alle Cookies abgelehnt
            werden, kann es jedoch zu Einschränkung von Funktionalitäten auf
            dieser und anderen Websites kommen. Sie können darüber hinaus die
            Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
            Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
            Verarbeitung dieser Daten durch Google verhindern, indem Sie
          </p>
          <p className="mt-2 leading-[28px]">
            a. Ihre Einwilligung in das Setzen des Cookies nicht erteilen oder
            <br />
            b. das Browser-Add-on zur Deaktivierung von Google Analytics{" "}
            <a
              className="text-primary font-semibold underline"
              href="https://tools.google.com/dlpage/gaoptout?hl=de"
            >
              HIER
            </a>{" "}
            herunterladen und installieren.
            <br />
            <br />
            Nähere Informationen zu Nutzungsbedingungen von Google Analytics,
            Google Maps und zum Datenschutz bei Google finden Sie unter
            <br />
            <a
              className="text-primary font-semibold underline"
              href="https://marketingplatform.google.com/about/analytics/terms/de/"
            >
              https://marketingplatform.google.com/about/analytics/terms/de/
            </a>
            ,{" "}
            <a
              className="text-primary font-semibold underline"
              href="https://www.google.com/intl/de_de/help/terms_maps/ "
            >
              https://www.google.com/intl/de_de/help/terms_maps/{" "}
            </a>{" "}
            und unter{" "}
            <a
              className="text-primary font-semibold underline"
              href="https://policies.google.com/?hl=de"
            >
              https://policies.google.com/?hl=de
            </a>
            .
          </p>

          <h2 className="mt-4 italic font-semibold">
            3. Einsatz von Firebase Crashlytics
          </h2>
          <p className="mt-2 leading-[28px]">
            Soweit Sie Ihre Einwilligung erklärt haben, wird in der mudioo-App
            und im mudioo-cms Firebase Crashlytics eingesetzt. Firebase
            Crashlytics ist ein Dienst zur Analyse von Abstürzen der Google LLC.
            Google Maps ist ein Kartendienst der Google LLC. Verantwortliche
            Stelle für Nutzer in der EU/ dem EWR und der Schweiz ist Google
            Ireland Limited, Google Building Gordon House, 4 Barrow St, Dublin,
            D04 E5W5, Irland („Google“).
          </p>

          <h2 className="font-bold mt-5">Art und Zweck der Verarbeitung</h2>
          <p className="mt-2 leading-[28px]">
            Wir setzen Firebase Crashlytics zur Verbesserung der Stabilität und
            Zuverlässigkeit von mudioo ein.
            <br />
            <br />
            Im Falle eines Absturzes werden anonyme Informationen an die Server
            von Google übertragen (Zustand der App zum Zeitpunkt des Absturzes,
            Installation UUID, Crash-Trace, Hersteller und Betriebssystem des
          </p>

          <h2 className="font-bold mt-5">Empfänger</h2>
          <p className="mt-2 leading-[28px]">
            Empfänger der Daten sind/können sein
          </p>
          <ul class="list-disc ml-5">
            <li>
              Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
              Irland (als Auftragsverarbeiter nach Art. 28 DSGVO){" "}
            </li>
            <li>
              Google LLC, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA{" "}
            </li>
            <li>
              Alphabet Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043,
              USA
            </li>
            <li>Museumsbetreiber</li>
          </ul>

          <h2 className="font-bold mt-5">Drittlandtransfer</h2>

          <p className="mt-2 leading-[28px]">
            Für die USA hat die Europäische Kommission am 10.Juli 2023 ihren
            Angemessenheitsbeschluss angenommen. Google LLC ist nach dem EU-US
            Privacy Framework zertifiziert. Da Google-Server weltweit verteilt
            sind und eine Übertragung in Drittländer (beispielsweise nach
            Singapur) nicht völlig ausgeschlossen werden kann, haben wir mit dem
            Anbieter zudem die{" "}
            <a
              className="text-primary underline font-semibold"
              href="https://business.safety.google/adsprocessorterms/sccs/c2p/"
            >
              EU-Standardvertragsklauseln
            </a>{" "}
            abgeschlossen.
          </p>

          <h2 className="font-bold mt-5">Speicherdauer</h2>

          <p className="mt-2 leading-[28px]">
            Die von uns gesendeten und mit Cookies verknüpften Daten werden nach
            2 Jahren automatisch gelöscht. Die maximale Lebensdauer der Google
            Analytics Cookies beträgt 2 Jahre. Die Löschung von Daten, deren
            Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im
            Monat.
          </p>

          <h2 className="font-bold mt-5">Rechtsgrundlage</h2>

          <p className="mt-2 leading-[28px]">
            Rechtsgrundlage für diese Datenverarbeitung ist Ihre Einwilligung
            gem. Art. 6 Abs.1 S.1 lit.a DSGVO und § 25 Abs. 1 S.1 TTDSG.
          </p>

          <h2 className="font-bold mt-5">Widerruf</h2>

          <p className="mt-2 leading-[28px]">
            Sie können Ihre Einwilligung jederzeit widerrufen indem Sie in den
            Einstellungen der iOS-Apps die Funktion „Absturzberichte“
            deaktivieren.
          </p>
          <p className="mt-2 leading-[28px]">
            Bei den Android-Apps erfolgt die Deaktivierung grundlegend in den
            Android-Einstellungen. Öffnen Sie hierzu die Einstellungen App,
            wählen den Punkt „Google“ und dort im Dreipunkt-Menü oben rechts den
            Menüpunkt „Nutzung & Diagnose“. Hier können Sie das Senden der
            entsprechenden Daten deaktivieren. Weitere Informationen finden Sie
            in der Hilfe zu Ihrem Google-Konto.
            <br />
            <br />
            Weitere Informationen zum Datenschutz erhalten Sie in den
            Datenschutzhinweisen von Firebase Crashlytics unter
            <br />
            <a
              className="text-primary font-semibold underline"
              href="https://firebase.google.com/support/privacy"
            >
              https://firebase.google.com/support/privacy
            </a>{" "}
            sowie{" "}
            <a
              className="text-primary font-semibold underline"
              href="https://docs.fabric.io/apple/fabric/data-privacy.html#data-collection-policies"
            >
              https://docs.fabric.io/apple/fabric/data-privacy.html#data-collection-policies{" "}
            </a>
            .
          </p>

          <h2 className="mt-4 italic font-semibold">5. Einsatz von Strato</h2>

          <p className="mt-2 leading-[28px]">
            Wir hosten mudioo über den Anbieter Strato AG, Pascalstraße 10,
            10587 Berlin (nachfolgend: „Strato“). Wenn Sie mudioo nutzen,
            erfasst Strato Logfiles sowie Ihre IP-Adresse.
          </p>

          <h2 className="font-bold mt-5">Art und Zweck der Verarbeitung</h2>
          <p className="mt-2 leading-[28px]">
            Das mudioo CMS wird über Server des Anbieters Strato gehostet. Das
            Hosting ist erforderlich, um Ihnen mudioo auf Ihrem Gerät anzeigen
            zu können.{" "}
          </p>

          <h2 className="font-bold mt-5">Rechtsgrundlage</h2>
          <p className="mt-2 leading-[28px]">
            Die Verwendung von Strato erfolgt auf Grundlage von Art. 6 Abs. 1
            lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer
            zuverlässigen Darstellung unserer Website. Sofern eine entsprechende
            Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO.
            <br />
            <br />
            Weitere Informationen zum Hosting mit Strato:{" "}
            <a
              className="text-primary underline font-semibold"
              href="https://www.strato.de/datenschutz/"
            >
              https://www.strato.de/datenschutz/
            </a>
            .
          </p>

          <h2 className="mt-4 italic font-semibold">
            2. Voraussetzungen der Weitergabe von personenbezogenen Daten an
            Drittländer
          </h2>

          <p className="mt-2 leading-[28px]">
            (1) Im Rahmen unserer Geschäftsbeziehungen können Ihre
            personenbezogenen Daten an Drittgesellschaften weitergegeben oder
            offengelegt werden. Diese können sich auch außerhalb des
            Europäischen Wirtschaftsraums (EWR), also in Drittländern, befinden.
            Eine derartige Verarbeitung erfolgt ausschließlich zur Erfüllung der
            vertraglichen und geschäftlichen Verpflichtungen und zur Pflege
            Ihrer Geschäftsbeziehung zu uns (Rechtsgrundlage ist Art. 6 Abs. 1
            lit b oder lit. f jeweils iVm Art. 44 ff. DS-GVO). Über die
            jeweiligen Einzelheiten der Weitergabe unterrichten wir Sie
            nachfolgend an den dafür relevanten Stellen.
            <div className="h-2" />
            (2) Einigen Drittländern bescheinigt die Europäische Kommission
            durch sog. Angemessenheitsbeschlüsse einen Datenschutz, der dem
            EWR-Standard vergleichbar ist (eine Liste dieser Länder sowie eine
            Kopie der Angemessenheitsbeschlüsse erhalten Sie hier:
            https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en).
            In anderen Drittländern, in die ggf. personenbezogene Daten
            übertragen werden, herrscht aber unter Umständen wegen fehlender
            gesetzlicher Bestimmungen kein durchgängig hohes Datenschutzniveau.
            Soweit dies der Fall ist, achten wir darauf, dass der Datenschutz
            ausreichend gewährleistet ist. Möglich ist dies über bindende
            Unternehmensvorschriften, Standard-Vertragsklauseln der Europäischen
            Kommission zum Schutz personenbezogener Daten gem. Art. 46 Abs. 1 ,
            2 lit. c DS-GVO (die Standard-Vertragsklauseln von 2021 sind
            verfügbar unter
            https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32021D0915&locale-en),
            Zertifikate oder anerkannte Verhaltenskodizes.
          </p>

          <h2 className="mt-4 italic font-semibold">
            3. Gesetzliche Verpflichtung zur Übermittlung bestimmter Daten
          </h2>
          <p className="mt-2 leading-[28px]">
            Wir können unter Umständen einer besonderen gesetzlichen oder
            rechtlichen Verpflichtung unterliegen, die rechtmäßig verarbeiteten
            personenbezogenen Daten für Dritte, insbesondere öffentlichen
            Stellen, bereitzustellen ( Art. 6 Abs. 1 S. 1 lit. c DS-GVO).
          </p>

          <h1 className="font-bold mt-8 text-xl">F. Ihre Rechte</h1>
          <h2 className="mt-4 italic font-semibold">1. Auskunftsrecht</h2>
          <p className="mt-2 leading-[28px]">
            (1) Sie haben gegenüber uns das Recht im Umfang von Art. 15 DS-GVO,
            Auskunft über die Sie betreffenden personenbezogenen Daten zu
            erhalten.
            <div className="h-2" />
            (2) Hierfür ist ein Antrag von Ihnen erforderlich, der entweder per
            E-Mail oder postalisch an die oben angegebenen Adressen (siehe D.
            1.) zu senden ist.
          </p>

          <h2 className="mt-4 italic font-semibold">
            2. Recht auf Widerspruch gegen die Datenverarbeitung und Widerruf
            der Einwilligung
          </h2>
          <p className="mt-2 leading-[28px]">
            (1) Sie haben gemäß Art. 21 DS-GVO das Recht, jederzeit gegen die
            Verarbeitung Sie betreffender personenbezogener Daten Widerspruch
            einzulegen. Wir werden die Verarbeitung Ihrer personenbezogenen
            Daten einstellen, es sei denn, wir können zwingende schutzwürdige
            Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte
            und Freiheiten überwiegen, oder wenn die Verarbeitung der
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
            dient.
            <div className="h-2" />
            (2) Gemäß Art. 7 Abs. 3 DS-GVO haben Sie das Recht Ihre einmal (auch
            vor der Geltung der DS-GVO, dh vor dem 25.5.2018) erteilte
            Einwilligung – also Ihr freiwilliger, in informierter Weise und
            unmissverständlich durch eine Erklärung oder eine sonstige
            eindeutige bestätigende Handlung verständlich gemachter Willen, dass
            Sie mit der Verarbeitung der betreffenden personenbezogenen Daten
            für einen oder mehrere bestimmte Zwecke einverstanden sind –
            jederzeit uns gegenüber zu widerrufen, falls Sie eine solche erteilt
            haben. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf
            dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen
            dürfen.
            <div className="h-2" />
            (3) Diesbezüglich wenden Sie sich bitte an die oben angegebene
            Kontaktstelle (siehe D. 1.).
          </p>

          <h2 className="mt-4 italic font-semibold">
            3. Recht zur Berichtigung und Löschung
          </h2>
          <p className="mt-2 leading-[28px]">
            (1) Insoweit Sie betreffende personenbezogene Daten unrichtig sind,
            haben Sie gemäß Art. 16 DS-GVO das Recht, von uns die unverzügliche
            Berichtigung zu verlangen. Mit einem diesbezüglichen Antrag wenden
            Sie sich bitte an die oben angegebene Kontaktstelle (siehe D. 1.).
            <div className="h-2" />
            (2) Unter den in Art. 17 DS-GVO genannten Voraussetzungen steht
            Ihnen das Recht zu, die Löschung Sie betreffender personenbezogener
            Daten zu verlangen. Mit einem diesbezüglichen Antrag wenden Sie sich
            bitte an die oben angegebene Kontaktstelle (siehe D. 1.). Das Recht
            auf Löschung steht Ihnen insbesondere zu, wenn die fraglichen Daten
            für die Erhebungs- oder Verarbeitungszwecke nicht mehr notwendig
            sind, wenn der Datenspeicherungszeitraum (siehe C. 7.) verstrichen
            ist, ein Widerspruch vorliegt (siehe G. 2.), oder eine unrechtmäßige
            Verarbeitung vorliegt.
          </p>

          <h2 className="mt-4 italic font-semibold">
            4. Recht auf Einschränkung der Verarbeitung
          </h2>
          <p className="mt-2 leading-[28px]">
            (1) Nach Maßgabe des Art. 18 DS-GVO haben Sie das Recht, von uns die
            Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
            verlangen.
            <div className="h-2" />
            (2) Mit einem diesbezüglichen Antrag wenden Sie sich bitte an die
            oben angegebene Kontaktstelle (siehe D. 1.).
            <div className="h-2" />
            (3) Das Recht auf Einschränkung der Verarbeitung steht Ihnen
            insbesondere zu, wenn die Richtigkeit der personenbezogenen Daten
            zwischen Ihnen und uns umstritten ist; das Recht steht Ihnen in
            diesem Fall für eine Zeitspanne zu, die für die Überprüfung der
            Richtigkeit erfordert wird. Entsprechendes gilt, wenn die
            erfolgreiche Ausübung eines Widerspruchsrechts (siehe G. 2.)
            zwischen Ihnen und uns noch umstritten ist. Dieses Recht steht Ihnen
            außerdem insbesondere dann zu, wenn Ihnen ein Recht auf Löschung
            zusteht (siehe G. 3.) und Sie anstelle einer Löschung eine
            eingeschränkte Verarbeitung verlangen.
          </p>

          <h2 className="mt-4 italic font-semibold">
            5. Recht auf Datenübertragbarkeit
          </h2>
          <p className="mt-2 leading-[28px]">
            (1) Nach Maßgabe des Art. 20 DS-GVO haben Sie das Recht, von uns die
            Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt
            haben, in einem strukturierten, gängigen, maschinenlesbaren Format
            nach Maßgabe zu erhalten.
            <div className="h-2" />
            (2) Mit einem diesbezüglichen Antrag wenden Sie sich bitte an die
            oben angegebene Kontaktstelle (siehe D. 1.).
          </p>

          <h2 className="mt-4 italic font-semibold">
            6. Recht auf Beschwerde bei der Aufsichtsbehörde
          </h2>
          <p className="mt-2 leading-[28px]">
            (1) Sie haben gemäß Art. 77 DS-GVO das Recht, sich über die Erhebung
            und Verarbeitung Ihrer personenbezogenen Daten bei der zuständigen
            Aufsichtsbehörde zu beschweren.
            <div className="h-2" />
            (2) Die zuständige Aufsichtsbehörde erreichen Sie unter folgenden
            Kontaktdaten: Unabhängiges Landeszentrum für Datenschutz
            Schleswig-Holstein, Holstenstraße 98, 24103 Kiel.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AppDatapolicy;
