import React, { useState } from "react";

function Newsletter() {
  const [email, setEmail] = useState("");

  const [formSent, setFormSent] = useState(false);
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const xhr = new XMLHttpRequest();
    const url =
      "https://api.hsforms.com/submissions/v3/integration/submit/27107010/fe5c0352-5b6f-4233-bf32-48d8ace18fba";
    const data = {
      fields: [{ name: "email", value: email }],
      context: {
        pageUri: "www.mudioo.de",
        pageName: "mudioo Newslletter",
      },
    };

    setEmailError("");

    if (!validateEmail(email)) {
      if (!validateEmail(email)) {
        setEmailError("Bitte geben Sie eine valide E-Mail an.");
        setEmail("");
      }
    } else {
      const final_data = JSON.stringify(data);

      xhr.open("POST", url);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          setFormSent(true);
        } else if (xhr.readyState === 4 && xhr.status === 403) {
          alert(
            "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie uns per Email."
          );
        } else if (xhr.readyState === 4 && xhr.status === 404) {
          alert(
            "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie uns per Email."
          );
        }
      };
      xhr.send(final_data);
      setEmail("");
    }
  };

  return (
    <div className="flex lg:px-0 px-5 justify-center w-full ">
      <div className="w-full max-w-5xl bg-primary overflow-hidden rounded-2xl relative p-6 lg:p-16 flex-col flex">
        <h2 className="lg:text-5xl lg:text-left text-left text-2xl z-20 text-white font-bold lg:leading-[60px]">
          Nichts verpassen &<br className="lg:block hidden" /> Newsletter
          abbonieren.
        </h2>
        <form
          onSubmit={handleSubmit}
          className="mt-5 lg:mt-8 w-full lg:w-2/3 lg:items-center"
        >
          <div className="flex lg:flex-row mb-5 flex-col">
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`h-14 lg:w-3/4 w-full  z-20 rounded-lg  px-3 border-[#F7F8FF] border ${
                emailError ? "placeholder-red" : "placeholder-darkgray"
              }`}
              placeholder={emailError ? emailError : "Email"}
            />
            <input
              type="submit"
              value="Abbonieren"
              className="bg-dark cursor-pointer h-14 z-20 w-full lg:w-1/4 rounded-lg lg:ml-3 lg:mt-0 mt-3 flex items-center justify-center text-white"
            />

            <div className="bg-secondary absolute -top-[470px] lg:-top-64 z-10 -left-56 lg:-left-36 w-[500px] h-[500px] rounded-full" />
            <div className="bg-secondary absolute top-36 lg:top-42 -right-44 w-96 h-96 rounded-full" />
          </div>
        </form>
        {formSent && (
          <div className="text-[#1ae895] font-semibold mt-5 text-lg">
            Sie haben sich erfolgreich für den Newsletter eingetragen.
          </div>
        )}
      </div>
    </div>
  );
}

export default Newsletter;
