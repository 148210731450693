import React, { useState } from "react";
import AnimateHeight from "react-animate-height";

export default function Testamonials() {
  const [showTestamonial, setShowTesTamonail] = useState([true, false, false]);
  const [testamonials, setTestamonials] = useState([
    {
      name: "Judith Doll",
      role: "Museumsdesignerin, Zoologisches Museum der CAU Kiel",
      img: require("../Assets/zoologisches_museum_kiel_judith_doll.jpg"),
      title: "Listen to the museum",
      review: (
        <div>
          „Listen to the museum – so lautet unser Ziel mit mudioo.
          <br />
          Wir möchten unseren Stories im Zoologischen Museum in Kiel eine Stimme
          geben, welcher unsere Besucher:innen noch dazu in verschiedenen
          Sprachen lauschen können. Auch großartig: Der Inhalt des Guides kann
          einfach und sehr schnell von uns selbst erstellt werden.“
        </div>
      ),
    },
    {
      name: "Dr. Ann Katrin Meyer",
      role: "Museumsleiterin, Turmhügelburg Lütjenburg",
      img: require("../Assets/turmhügelburg_lütjenburg.png"),
      title: "Ein großer Mehrwert für unser Museum",
      review: (
        <div>
          “Wir können mit der mudioo-Software einen hochwertigen App-Guide für
          die Besucher:innen unserer Turmhügelburg erstellen. Dabei sind wir in
          der Gestaltung sehr flexibel und es werden nur geringe Kosten
          verursacht. <br /> <br /> Zusätzlich gefällt uns, dass die Software
          laufend geupdatet wird, und dass die Nutzung sowohl für uns als Museum
          als auch für die Besucher sehr einfach und niedrigschwellig angelegt
          ist."{" "}
        </div>
      ),
    },
    {
      name: "Dr. Matthias Alberti",
      role: "Museumsleiter, Geologisches und Mineralogisches Museum der CAU Kiel",
      img: require("../Assets/alberti.jpg"),
      title:
        "Der Audioguide von Mudioo macht den Besuch unserer Ausstellung abseits von Führungen und Veranstaltungen unterhaltsamer und attraktiver",
      review: (
        <div>
          Das Angebot ist ein Beitrag zur Inklusion und die Nutzung
          verschiedener Sprachen erreicht neue Zielgruppen. Uns gefällt
          besonders, dass die Einrichtung unkompliziert, schnell und flexibel
          ist. So können auch Korrekturen oder Aktualisierungen der Texte zügig
          umgesetzt werden. Die App ist im Layout einfach und übersichtlich.
        </div>
      ),
    },
  ]);
  const [selectedTestamonialIndex, setSelectedTestamonialindex] = useState(0);

  const selectNewTestamonialMobile = (selectedTestamonialIndex) => {
    const newShowTestamonialArray = Array(showTestamonial.length).fill(false);
    newShowTestamonialArray[selectedTestamonialIndex] = showTestamonial[
      selectedTestamonialIndex
    ]
      ? false
      : true;
    setShowTesTamonail(newShowTestamonialArray);
  };

  return (
    <div className="lg:px-16 lg:pb-20 pb-10 lg:pt-5 pt-0 w-full flex flex-col items-center ">
      <div className="w-full flex flex-col items-center pt-20 lg:pt-28 lg:px-0 px-0 pb-12 lg:pb-0 max-w-5xl">
        <h2 className="text-3xl px-5 lg:p-0 text-dark leading-[44px] text-start w-full font-bold ">
          Das sagen Ihre Kolleg:innen zu mudioo
        </h2>

        <div className="px-5 mt-5 lg:mt-10 w-full lg:hidden flex-col">
          {testamonials.map((item, index) => {
            return (
              <div className="mt-2">
                <div
                  onClick={() => {
                    selectNewTestamonialMobile(index);
                    setSelectedTestamonialindex(index);
                  }}
                  className={`w-full flex items-center p-4 bg-white transition-all duration-100 border-border ${
                    showTestamonial[index]
                      ? "border-t border-r border-l rounded-tl-lg rounded-tr-lg"
                      : "border rounded-lg"
                  }`}
                >
                  <TestamonialAuthorItem
                    img={item.img}
                    name={item.name}
                    role={item.role}
                    selected={showTestamonial[index]}
                  />
                  <div
                    className={`w-8 flex transition-all items-center mt-2 justify-center text-darkgray h-8 ml-2 shrink-0 ${
                      showTestamonial[index] ? "-rotate-0" : "-rotate-90"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </div>
                </div>

                <AnimateHeight
                  duration={500}
                  height={showTestamonial[index] ? "auto" : 0} // see props documentation below
                >
                  <div
                    className={`w-full transition-all border-b border-l border-r  rounded-bl-lg rounded-br-lg p-5 pt-2 bg-white border-border ${
                      showTestamonial[index] ? "" : ""
                    }`}
                  >
                    <h1 className="font-semibold text-2xl mb-2 text-dark">
                      {testamonials[selectedTestamonialIndex].title}
                    </h1>
                    <div className="flex mb-4">
                      {["1", "2", "3", "4", "5"].map(() => {
                        return (
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="#f2cb1d"
                              className="w-5 h-5"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                        );
                      })}
                    </div>
                    {testamonials[selectedTestamonialIndex].review}
                  </div>
                </AnimateHeight>
              </div>
            );
          })}
        </div>

        <div className="lg:flex hidden w-full mt-16">
          <div className="w-5/12 shrink-0 mr-12">
            {testamonials.map((item, index) => {
              return (
                <div
                  className="cursor-pointer"
                  onClick={() => setSelectedTestamonialindex(index)}
                >
                  <TestamonialAuthorItem
                    img={item.img}
                    name={item.name}
                    role={item.role}
                    selected={index === selectedTestamonialIndex}
                  />
                </div>
              );
            })}
          </div>

          <div className="ml-4 flex-grow text-base text-darkgray">
            <h1 className="font-semibold text-2xl mb-2 text-dark">
              {testamonials[selectedTestamonialIndex].title}
            </h1>
            <div className="flex mb-4">
              {["1", "2", "3", "4", "5"].map(() => {
                return (
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="#f2cb1d"
                      className="w-5 h-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                );
              })}
            </div>
            {testamonials[selectedTestamonialIndex].review}
          </div>
        </div>
      </div>
    </div>
  );
}

const TestamonialAuthorItem = ({ img, name, role, selected }) => {
  return (
    <div
      className={`flex items-center border-0  lg:border rounded-xl w-full p-0 lg:mt-0  lg:p-5 ${
        selected ? "lg:bg-lightgray lg:border-border" : "border-white"
      }`}
    >
      <div className="h-16 shrink-0 w-16 relative border-2 border-lightgray overflow-hidden rounded-full bg-lightgray">
        <img className="" alt={`Foto von ${name}`} src={img} />
      </div>

      <div className="ml-3">
        <h1 className="text-dark font-semibold text-lg">{name}</h1>
        <h2 className="text-darkgray text-xs lg:text-sm">{role}</h2>
      </div>
    </div>
  );
};
