import { useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { isMobile } from "react-device-detect";

function Advantages() {
  const { scrollY } = useScroll();
  const translateXLeft = useTransform(scrollY, [3000, 3600], [40, 0]);
  const translateXRight = useTransform(scrollY, [3000, 3600], [-40, 0]);

  const translateYTop = useTransform(scrollY, [2600, 3600], [40, 0]);
  const translateYBottom = useTransform(scrollY, [2600, 3600], [-40, 0]);

  return (
    <div className="w-full flex justify-center">
      <div className="w-full flex flex-col items-center pt-20 lg:pt-16 lg:px-0 px-5 pb-12 max-w-5xl">
        <h2 className="text-3xl text-dark lg:text-center  w-full font-bold ">
          Vorteile von mudioo
        </h2>

        <div className="grid lg:grid-cols-3 grid-cols-1 w-full lg:gap-8 gap-2 lg:mt-16 mt-8">
          <motion.div
            className="flex w-full"
            style={
              isMobile ? { x: 0 } : { x: translateXLeft, y: translateYTop }
            }
          >
            <AdvantagItem
              title={"Audioguides erstellen"}
              text={
                "Einfach mit unserer KI Texte in Sprache umwandeln oder selbst Audiodateien aufnehmen."
              }
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="white"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"
                  />
                </svg>
              }
            />
          </motion.div>

          <motion.div
            className="flex w-full"
            style={isMobile ? { x: 0 } : { y: translateYTop }}
          >
            <AdvantagItem
              title={"Übersetzung"}
              text={
                "Dank verschiedener KI’s können Sie alle Inhalte mit wenigen Klicks in verschiedene Sprachen übersetzen."
              }
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="white"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802"
                  />
                </svg>
              }
            />
          </motion.div>

          <motion.div
            className="flex w-full flex-stretch"
            style={
              isMobile ? { x: 0 } : { y: translateYTop, x: translateXRight }
            }
          >
            <AdvantagItem
              title={"GPS"}
              text={
                "Platzieren Sie Ihre Exponate auf einer Karte, um Ihren Besucher:innen einen digitalen Rundgang über die mudioo-App anzubieten."
              }
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="white"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                  />
                </svg>
              }
            />
          </motion.div>

          <motion.div
            className="flex"
            style={
              isMobile ? { x: 0 } : { y: translateYBottom, x: translateXLeft }
            }
          >
            <AdvantagItem
              title={"Inklusion"}
              text={
                "Die mudioo App ermöglicht barrierefreie Ausstellungserlebnisse."
              }
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="white"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
                  />
                </svg>
              }
            />
          </motion.div>

          <motion.div
            className="flex"
            style={isMobile ? { x: 0 } : { y: translateYBottom }}
          >
            <AdvantagItem
              title={"Ohne Fachwissen"}
              text={
                "Die Software kann von jeder Person angewendet werden, denn es ist kein technisches Fachwissen notwendig."
              }
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="white"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
                  />
                </svg>
              }
            />
          </motion.div>

          <motion.div
            className="flex"
            style={
              isMobile ? { x: 0 } : { y: translateYBottom, x: translateXRight }
            }
          >
            <AdvantagItem
              title={"Planungssicherheit"}
              text={
                "Die Software wird laufend auf dem neuesten technischen Standard gehalten und der mudioo-Support ist stets für Sie da."
              }
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="white"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5"
                  />
                </svg>
              }
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
}

function AdvantagItem({ icon, title, text, soon }) {
  const [showItem, setShowItem] = useState(false);
  return (
    <div
      style={{ boxShadow: "0px 0px 0px #f0f0f0" }}
      className="lg:p-8 p-4 bg-[#FCFBFF] w-full flex flex-col lg:items-center relative rounded-lg"
    >
      {soon && (
        <div className="w-28 h-8 font-semibold bg-[#616FDB] hidden lg:flex text-sm text-white items-center justify-center absolute -top-0 -right-5 rotate-12 rounded">
          coming soon
        </div>
      )}

      <div
        onClick={() => {
          setShowItem(!showItem);
        }}
        className="flex relative flex-row lg:flex-col justify-between items-center"
      >
        <div className="flex  lg:flex-col items-center">
          <div className="lg:w-14 lg:h-14 w-12 h-12 lg:mb-5 aspect-square rounded-full  bg-primary flex justify-center items-center">
            {icon}
          </div>

          <div>
            <h2 className="text-xl lg:ml-0 ml-5 text-text text-left lg:text-center font-bold ">
              {title}
            </h2>
            {soon && (
              <div className="ml-5 flex lg:hidden text-sm font-semibold text-primary">
                coming soon
              </div>
            )}
          </div>
        </div>

        <div
          className={`lg:w-14 lg:hidden opacity-70 lg:h-14 w-12 h-12 lg:mb-5 aspect-square rounded-full transition-all  flex justify-center items-center ${
            showItem ? "-rotate-180" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#d8d3e3"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      </div>
      <p
        className={`text-darkgray transition-all lg:text-center  text-lg lg:py-0 py-3 lg:text-base mt-3 ${
          showItem ? "flex" : "lg:flex hidden"
        }`}
      >
        {text}
      </p>
    </div>
  );
}

export default Advantages;
