import { useState, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { isMobile } from "react-device-detect";

function Pricing() {
  //   const { scrollY } = useScroll();
  //   const translateXLeft = useTransform(scrollY, [1400, 1800], [40, 0]);
  //   const translateXRight = useTransform(scrollY, [1400, 1800], [-40, 0]);

  //   const translateYTop = useTransform(scrollY, [1400, 1800], [40, 0]);
  //   const translateYBottom = useTransform(scrollY, [1400, 1800], [-40, 0]);
  const [priceOption, setPriceOption] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const [switchBtn, setSwitchBtn] = useState(true);

  // useEffect(() => {
  //   if (!isVisible) {
  //     setIsVisible(true);
  //   }
  // }, [isVisible]);

  useEffect(() => {
    // Simulating state change after a certain time interval
    if (!isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(!isVisible);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  // useEffect(() => {
  //   if (priceOption) {
  //     setIsVisible(false);
  //   }
  // }, [priceOption]);

  return (
    <div className="w-full flex justify-center bg-gradient-to-b from-[#FCFBFF] pb-10 to-white">
      <div className="w-full relative flex flex-col items-center pt-5 lg:pt-20 lg:px-0 px-5 pb-12 max-w-5xl">
        <h2 className="text-3xl text-dark lg:text-center w-full font-bold ">
          Preise für mudioo
        </h2>
        {/* //zzgl MwSt */}
        <p className="text-darkgray lg:text-center  lg:text-center text-lg  lg:mt-5 mt-3">
          Die mudioo-Software kann im Jahresabo oder im Monatsabo genutzt
          werden.
          <div className="hidden lg:block" /> Außerdem kann die Software{" "}
          <span className="font-bold text-dark">einen Monat kostenlos </span>{" "}
          getestet werden.
        </p>

        <div className="flex items-center mt-10 lg:mt-5">
          <div
            onClick={() => {
              if (!priceOption) {
                setSwitchBtn(true);
                setIsVisible(!isVisible);
                setTimeout(() => {
                  setPriceOption(true);
                }, 500);
              }
            }}
            className={`cursor-pointer ${
              switchBtn
                ? "text-primary underline font-bold"
                : "font-semibold text-text"
            }`}
          >
            Jahresabo
          </div>
          <div
            onClick={() => {
              setSwitchBtn(!switchBtn);
              setIsVisible(!isVisible);
              setTimeout(() => {
                setPriceOption(!priceOption);
              }, 500);
            }}
            className="w-14 cursor-pointer border border-border h-8 flex items-center  rounded-full bg-lightblue mx-5"
          >
            <div
              className={`w-6 h-6 rounded-full bg-primary ml-1 transition-all ${
                switchBtn ? "translate-x-0" : "translate-x-6"
              }`}
            />
          </div>
          <div
            onClick={() => {
              if (priceOption) {
                setSwitchBtn(false);
                setIsVisible(!isVisible);
                setTimeout(() => {
                  setPriceOption(false);
                }, 500);
              }
            }}
            className={`cursor-pointer ${
              !switchBtn
                ? "text-primary underline font-bold"
                : "text-text font-semibold"
            }`}
          >
            Monatsabo
          </div>
        </div>
        <div
          className={`transition-opacity duration-500 ${
            isVisible ? "opacity-100" : "opacity-0"
          }`}
        >
          {priceOption ? (
            <>
              <div className="grid lg:grid-cols-3 grid-cols-1 w-full lg:gap-4 gap-2 mt-10">
                <div className="p-12 py-8 text-white w-full bg-[#161A34] text-center flex-col rounded-lg justify-center">
                  <div className="lg:h-80">
                    <h1 className="text-white font-semibold text-lg ">
                      Jahresabo
                    </h1>
                    <div className="flex items-center mt-6 justify-center">
                      <div className="text-4xl font-bold">139€</div>
                      <div className="ml-1 font-semibold">/ Monat</div>
                    </div>

                    <div className="text-center my-3 text-[#aaacb3] text-lg">
                      - oder -
                    </div>
                    <div>
                      <span className="font-bold">1600€</span>/Jahr
                    </div>

                    <div className="w-full h-0.5 my-8 bg-[#232944] w-full rounded-full" />

                    <div className="flex items-center">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </div>
                      <div className="text-semibold ml-1">
                        Voller Funktionsumfang
                      </div>
                    </div>

                    <div className="flex items-center mt-3">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </div>
                      <div className="text-semibold ml-1">Jährlich kündbar</div>
                    </div>

                    <div className="flex items-center mt-3">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </div>
                      <div className="text-semibold ml-1">
                        Inkl. 40 Exponate
                      </div>
                    </div>
                  </div>
                  <a
                    target="_blank"
                    href="https://calendly.com/christoph-v-trotha/-mudioo"
                    className="w-full font-semibold h-12 mt-8 bg-primary flex items-center justify-center rounded-lg text-white"
                  >
                    Kostenlos testen
                  </a>
                </div>

                {/* Erweiterung */}

                <div className="p-12 py-8 text-white w-full bg-primary text-center flex-col rounded-lg justify-center">
                  <div className="lg:h-72">
                    <h1 className="text-white font-semibold text-lg ">
                      40- Exponate- <br /> Erweiterung
                    </h1>
                    <div className="flex items-center mt-6 justify-center">
                      <div className="text-4xl font-bold">40€</div>
                      <div className="ml-1 font-semibold">/ Monat</div>
                    </div>

                    <div className="text-center my-3 text-lightblue text-lg">
                      - oder -
                    </div>
                    <div>
                      <span className="font-bold">400€</span>/Jahr
                    </div>

                    <div className="w-full h-0.5 my-8 bg-secondary w-full rounded-full" />

                    <div>
                      Erweitert die Kapazität vom Monats- und Jahresabo um 40
                      Exponate. Kann beliebig oft dazugebucht werden.
                    </div>
                  </div>
                  {/* <a
              href="/getbeta"
              className="w-full font-semibold h-12 mt-8 bg-secondary flex items-center justify-center rounded-lg text-white"
            >
              Jetzt loslegen
            </a> */}
                </div>

                <div className="p-12 py-8 relative text-white w-full bg-primary text-center flex-col rounded-lg justify-center">
                  <div className="lg:h-72">
                    <h1 className="text-white font-semibold text-lg ">
                      Solidarische Preisgestaltung
                    </h1>
                    <div className="flex items-center mt-6 justify-center">
                      Sofern Sie die angegebenen Kosten nicht leisten können,
                      kontaktieren Sie uns bitte, dann ermöglichen wir eine
                      solidarische Preisanpassung.
                    </div>

                    <div className="w-full h-0.5 my-8 bg-secondary w-full rounded-full" />

                    <div className="flex items-center">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </div>
                      <div className="text-semibold ml-1">
                        Alle Basisfunktionen
                      </div>
                    </div>

                    <div className="flex items-center mt-3">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </div>
                      <div className="text-semibold ml-1">Jährlich kündbar</div>
                    </div>
                  </div>

                  <a
                    target="_blank"
                    href="https://calendly.com/christoph-v-trotha/-mudioo"
                    className="w-full bottom-0 font-semibold h-12 mt-16 bg-secondary flex items-center justify-center rounded-lg text-white"
                  >
                    Kontakt aufnehmen
                  </a>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="grid lg:grid-cols-3 grid-cols-1 w-full lg:gap-4 gap-2 mt-10">
                <div className="p-12 py-8 text-white w-full bg-[#161A34] text-center flex-col rounded-lg justify-center">
                  <div className="lg:h-80">
                    <h1 className="text-white font-semibold text-lg ">
                      Monatsabo
                    </h1>
                    <div className="flex items-center mt-6 justify-center">
                      <div className="text-4xl font-bold">189€</div>
                      <div className="ml-1 font-semibold">/ Monat</div>
                    </div>

                    <div className="w-full h-0.5 my-8 bg-[#232944] w-full rounded-full" />

                    <div className="flex items-center">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </div>
                      <div className="text-semibold ml-1">
                        Voller Funktionsumfang
                      </div>
                    </div>

                    <div className="flex items-center mt-3">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </div>
                      <div className="text-semibold ml-1">
                        Monatlich kündbar
                      </div>
                    </div>

                    <div className="flex items-center mt-3">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </div>
                      <div className="text-semibold ml-1">
                        Inkl. 40 Exponate
                      </div>
                    </div>
                  </div>
                  <a
                    target="_blank"
                    href="https://calendly.com/christoph-v-trotha/-mudioo"
                    className="w-full font-semibold h-12 mt-8 bg-primary flex items-center justify-center rounded-lg text-white"
                  >
                    Kostenlos testen
                  </a>
                </div>

                {/* Erweiterung */}

                <div className="p-12 py-8 text-white w-full bg-primary text-center flex-col rounded-lg justify-center">
                  <div className="lg:h-72">
                    <h1 className="text-white font-semibold text-lg ">
                      40- Exponate- <br /> Erweiterung
                    </h1>
                    <div className="flex items-center mt-6 justify-center">
                      <div className="text-4xl font-bold">60€</div>
                      <div className="ml-1 font-semibold">/ Monat</div>
                    </div>

                    <div className="w-full h-0.5 my-8 bg-secondary w-full rounded-full" />

                    <div>
                      Erweitert die Kapazität vom Monats- und Jahresabo um 40
                      Exponate. Kann beliebig oft dazugebucht werden.
                    </div>
                  </div>
                  {/* <a
              href="/getbeta"
              className="w-full font-semibold h-12 mt-8 bg-secondary flex items-center justify-center rounded-lg text-white"
            >
              Jetzt loslegen
            </a> */}
                </div>

                <div className="p-12 py-8 relative text-white w-full bg-primary text-center flex-col rounded-lg justify-center">
                  <div className="lg:h-72">
                    <h1 className="text-white font-semibold text-lg ">
                      Solidarische Preisgestaltung
                    </h1>
                    <div className="flex items-center mt-6 justify-center">
                      Sofern Sie die angegebenen Kosten nicht leisten können,
                      kontaktieren Sie uns bitte, dann ermöglichen wir eine
                      solidarische Preisanpassung.
                    </div>

                    <div className="w-full h-0.5 my-8 bg-secondary w-full rounded-full" />

                    <div className="flex items-center">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </div>
                      <div className="text-semibold ml-1">
                        Alle Basisfunktionen
                      </div>
                    </div>

                    <div className="flex items-center mt-3">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </div>
                      <div className="text-semibold ml-1">Jährlich kündbar</div>
                    </div>
                  </div>

                  <a
                    target="_blank"
                    href="https://calendly.com/christoph-v-trotha/-mudioo"
                    className="w-full bottom-0 font-semibold h-12 mt-16 bg-secondary flex items-center justify-center rounded-lg text-white"
                  >
                    Kontakt aufnehmen
                  </a>
                </div>
              </div>
            </>
          )}

          <div
            className={`absolute left-0 -bottom-5 transition-opacity duration-100 ${
              priceOption ? "opacity-100" : "opacity-0"
            }`}
          >
            <div className="absolute hidden lg:block h-20 -rotate-12 -bottom-5 lg:bottom-0 left-5 lg:-left-10">
              <img
                alt="mudioo Logo"
                src={require("../Assets/arrow.png")}
                className="h-full scale-75 ml-5 mt-5 lg:scale-100"
              />
            </div>

            <div className="text-text bg-lightgray lg:bg-white rounded-lg p-5 lg:p-0 font-semibold px-5 text-xl lg:text-lg leading-8 -mb-32 mx-5 lg:mx-0 lg:-mb-2 lg:pl-16">
              mudioo für mehrere Jahre? <div className="lg:hidden block" /> → 5
              Jahre: 6500€ / 3 Jahre: 3900€
            </div>
          </div>

          <div className="absolute text-sm bottom-3 lg:bottom-0 lg:px-0 px-5 right-0 text-darkgray">
            {" "}
            Alle Preise zuzüglich 19% MwSt.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
