import React, { useState } from "react";

function Contact({ bgmode, type }) {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [question, setQuestion] = useState("");
  const [accepted, setAccepted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [questionError, setQuestionError] = useState("");
  const [acceptedError, setAcceptedError] = useState("");

  const [formSent, setFormSent] = useState(false);

  const resetErrors = () => {
    setNameError("");
    setEmailError("");
    setLastNameError("");
    setQuestionError("");
    setAcceptedError("");
  };

  const resetForm = () => {
    setName("");
    setLastName("");
    setEmail("");
    setOrganisation("");
    setQuestion("");
    setAccepted("");
    setPhone("");
    resetErrors();
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setLoading(true);

    const xhr = new XMLHttpRequest();
    const url =
      "https://api.hsforms.com/submissions/v3/integration/submit/27107010/767b1cf2-cb54-4854-b3ef-e3d87cd6942d";
    const data = {
      fields: [
        { name: "email", value: email },
        { name: "email", value: phone },
        { name: "firstname", value: name },
        { name: "lastname", value: lastName },
        { name: "company", value: organisation },
        { name: "anliegen", value: question },
        { name: "typ", value: type },
        { name: "Nummer", value: phone },
      ],
      context: {
        pageUri: "www.mudioo.de",
        pageName: "mudioo Kontaktformular",
      },
    };

    resetErrors();

    if (
      !validateEmail(email) ||
      name.length < 1 ||
      lastName.length < 1 ||
      question < 6 ||
      !accepted
    ) {
      if (!validateEmail(email)) {
        setEmailError("Bitte geben Sie eine valide E-Mail an.");
      }
      if (name.length < 1) {
        setNameError("Bitte geben Sie Ihren Vornamen ein.");
      }
      if (lastName.length < 1) {
        setLastNameError("Bitte geben Sie Ihren Nachnamen ein.");
      }
      if (question.length < 6) {
        setQuestionError(
          "Bitte formulieren Sie Ihr Anliegen mit mindestens 6 Zeichen."
        );
      }
      if (!accepted) {
        setAcceptedError("Bitte akzeptieren Sie die Datenschutzerklärung.");
      }
      setLoading(false);
    } else {
      const final_data = JSON.stringify(data);

      xhr.open("POST", url);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          setFormSent(true);
          setLoading(false);
        } else if (xhr.readyState === 4 && xhr.status === 403) {
          alert(
            "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie uns per Email."
          );
          setLoading(false);
        } else if (xhr.readyState === 4 && xhr.status === 404) {
          alert(
            "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie uns per Email."
          );
          setLoading(false);
        }
      };

      xhr.send(final_data);
    }
  };

  return (
    <>
      <div
        className={`w-full flex relative flex-col ipad:px-0 px-5 relative py-20 lg:pt-20 pt-20 lg:mt-0  z-20   items-center ${
          bgmode ? "bg-primary" : ""
        }`}
      >
        {bgmode && (
          <div className="text-white z-20 mb-20 text-center text-4xl font-bold ">
            Haben Sie noch Fragen?
          </div>
        )}

        <form
          onSubmit={handleSubmit}
          className="lg:grid lg:grid-cols-2  z-20 gap-3 w-full max-w-5xl "
        >
          <div className="w-full ">
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={`h-14 w-full bg-white rounded px-3 border border-border`}
              placeholder="Vorname*"
            />
            <div className="text-[#fc6868] lg:mb-0 mb-3 font-bold mt-2 ">
              {nameError && <p>{nameError}</p>}
            </div>
          </div>

          <div className="relative">
            <input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className={`h-14 w-full bg-white rounded px-3 border border-border`}
              placeholder="Nachname*"
            />
            <div className="text-[#fc6868] lg:mb-0 mb-3 font-bold mt-2 ">
              {lastNameError && <p>{lastNameError}</p>}
            </div>
          </div>

          <div className="relative">
            <input
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className={`h-14 w-full bg-white rounded px-3 border border-border`}
              placeholder="Telefon"
            />
            <div className="text-[#fc6868] lg:mb-0 mb-3 font-bold mt-2 ">
              {/* {lastNameError && <p>{lastNameError}</p>} */}
            </div>
          </div>

          <div className="relative">
            <input
              value={organisation}
              onChange={(e) => setOrganisation(e.target.value)}
              className={`h-14 w-full bg-white rounded px-3 lg:mb-0 mb-3 border border-border`}
              placeholder="Name der Organisation"
            />
          </div>

          <div className="relative">
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="h-14 w-full bg-white rounded px-3 border border-border"
              placeholder="E-Mail*"
            />
            <div className="text-[#fc6868] lg:mb-0 mb-3 font-bold mt-2 ">
              {emailError && <p>{emailError}</p>}
            </div>
          </div>

          <div className="col-span-2">
            <textarea
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              className="h-44 w-full rounded  p-3 border border-border"
              placeholder="Ihr Anliegen*"
            ></textarea>
            <div className="text-[#fc6868] lg:mb-0 mb-3 font-bold mt-2 ">
              {questionError && <p>{questionError}</p>}
            </div>
          </div>

          <div
            className={`lg:col-span-2 mt-2 lg:flex-row flex-col flex justify-between items-center ${
              bgmode ? "text-white" : "text-dark"
            }`}
          >
            <div className="flex flex-row lg:mt-0 mt-4 lg:items-center">
              <div
                onClick={() => {
                  setAccepted(!accepted);
                  setAcceptedError("");
                }}
                className={`rounded-full lg:mt-0 mt-2 shrink-0 cursor-pointer border-border border h-8 w-14 mr-3 flex px-1 items-center ${
                  accepted ? "bg-white" : "bg-white"
                }`}
              >
                <div
                  className={`rounded-full h-6 w-6 transition-all ${
                    accepted
                      ? "bg-primary translate-x-6"
                      : "bg-[#e8e8e8] translate-x-0"
                  }`}
                />
              </div>
              <div className="lg:flex ml-2 lg:ml-0">
                Ich habe die
                <a
                  className="ml-1 inline-block font-bold mr-1 lg:flex underline"
                  href="/datapolicy"
                >
                  Datenschutzerklärung
                </a>
                gelesen und akzeptiere diese.
              </div>
            </div>

            <button
              type="submit"
              className={`lg:w-1/4 w-full mt-6 lg:mt-0 py-4 lg:py-2.5 text-lg text-white rounded-lg flex cursor-pointer justify-center font-semibold ${
                bgmode ? "bg-dark" : "bg-primary"
              }`}
              href="/"
            >
              {!loading ? (
                <div className="flex items-center">Absenden</div>
              ) : (
                <div>
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      className="w-7 h-7 py-1 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="white"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="blue"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </button>
          </div>
          <div>
            {acceptedError && (
              <div className="text-[#fc6868] font-bold mt-3 lg:-mt-5 mb-5">
                {acceptedError}
              </div>
            )}

            <div
              className={`text-sm lg:mt-0 mt-2 ${
                bgmode ? "text-white" : "text-darkgray"
              }`}
            >
              * Pflichtfeld
            </div>
          </div>
        </form>

        {bgmode && (
          <div className="absolute bottom-0 right-0 w-full h-full overflow-hidden">
            <div className="bg-secondary w-[800px] h-[800px] absolute -bottom-[430px] -right-[430px] rounded-full" />
            <div className="bg-secondary w-[400px] h-[400px] absolute -top-[150px] -left-[250px] rounded-full" />
          </div>
        )}
      </div>

      {/* form sent succesful */}

      {formSent && (
        <div className="fixed top-0 left-0  w-screen h-screen flex items-center justify-center z-50">
          <div className="absolute top-0 left-0 bg-dark w-screen h-screen opacity-90 " />
          <div className="absolute flex items-center justify-center flex-col bg-white p-10 rounded-2xl">
            <h1 className="text-dark font-bold text-2xl text-center">
              Anfrage verschickt.
            </h1>
            <p className="text-center text-lg mt-3 text-darkgray">
              Ihre Anfrage wurde erfoglreich versendet.
              <br /> Wir melden uns schnellstmöglich bei Ihnen.
            </p>
            <div
              onClick={() => {
                setFormSent(false);
                resetForm();
              }}
              className="rounded-lg cursor-pointer bg-primary py-2.5 text-lg px-8 text-white font-bold mt-6 "
            >
              Alles klar
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Contact;
