function Advantages2() {
  return (
    <div className="w-full bg-gradient-to-b from-white via-[#FCFBFF] to-white flex justify-center">
      <div className="w-full flex flex-col items-center pt-5 lg:pt-28 lg:px-0 px-5 pb-12 max-w-5xl">
        <h2 className="text-3xl text-dark lg:text-center leading-[44px] w-full font-bold ">
          Ihr ganzheitliches Konzept für digitale Wissensvermittlung
        </h2>

        <div className="w-full flex flex-col lg:flex-row mt-12">
          <div className="w-full lg:w-1/2 lg:p-8 rounded-lg mr-4">
            <h2 className="text-xl text-text mb-6 font-semibold">
              Für Kultureinrichtungen
            </h2>
            <CheckItem text="Keine Investitionskosten" />
            <CheckItem text="Keine langwierigen Agentur-Projekte" />
            <CheckItem text="Kein technisches Fachwissen erforderlich" />
            <CheckItem text="Keine außerplanmäßigen Folgeinvestitionen" />
          </div>
          <div className="w-full lg:w-1/2 pt-8 lg:p-8 rounded-lg mt-5 lg:mt-0 lg:ml-4">
            <h2 className="text-xl text-text mb-6 font-semibold">
              Für Besucher:innen
            </h2>

            <CheckItem text="Inhalte werden modern und zeitgemäß dargestellt" />
            <CheckItem text="Leichtere Wissensaufnahme durch die multimediale Anwendung" />
            <CheckItem text="Ansprache von jungen Zielgruppen" />
            <CheckItem text="Inklusives Angebot mit Funktionen für Barrierefreiheit" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advantages2;

function CheckItem({ text }) {
  return (
    <div className="flex text-dark mt-3">
      <div className="text-white shrink-0 rounded-full h-6 w-6 bg-primary flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={3}
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m4.5 12.75 6 6 9-13.5"
          />
        </svg>
      </div>
      <div className="ml-2 font-semibold text-text">{text}</div>
    </div>
  );
}
