import React from "react";

function HowToStart() {
  return (
    <div className="w-full flex justify-center bg-gradient-to-b from-white to-[#FCFBFF]">
      <div className="w-full relative flex flex-col items-center lg:items-start pt-0 lg:pt-20 lg:px-0 px-5 pb-12 max-w-5xl">
        <h2 className="text-3xl text-dark text-start  w-full font-bold ">
          Wie kann ich beginnen?
        </h2>
        {/* //zzgl MwSt */}
        <p className="text-darkgray text-lg  lg:mt-5 mt-3">
          Die folgenden 4 Schritte zeigen, wie mudioo bei Ihnen in die Anwendung
          kommt:
        </p>

        <div className="w-full grid grid-cols-1 lg:grid-cols-4 mt-10 relative gap-0">
          <div className="h-full w-1 bg-[#161A34] lg:hidden block absolute left-4 ml-0.25 top-0" />

          <div className="w-full pb-6 lg:pb-0 rounded-lg">
            <div className="flex items-center">
              <div className="rounded-full z-20 text-white font-bold  bg-[#161A34] h-9 w-9 flex items-center justify-center">
                1
              </div>
              <div className="h-1 hidden lg:flex flex-grow bg-[#161A34]" />
            </div>
            <h2 className="text-lg lg:mt-3 lg:ml-0 ml-12 -mt-8 font-bold ">
              Kennenlernen
            </h2>
            <p className="text-darkgray lg:ml-0 ml-12 mt-3 pr-10">
              In einem Online-Meeting werden die Funktionen und
              Einsatzmöglichkeiten der mudioo-Software ausführlich gezeigt und
              alle offenen Fragen geklärt.
            </p>

            <a
              className="font-semibold lg:justify-start justify-end lg:mr-0 mr-10 lg:ml-0 ml-12 text-primary text-sm  items-center flex  lg:mb-2 mt-2 lg:mt-4"
              href="https://calendly.com/christoph-v-trotha/-mudioo"
              target="_blank"
            >
              Jetzt Termin vereinbaren
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-7 h-7 ml-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </a>
          </div>

          <div className="w-full pb-6 lg:pb-0 rounded-lg">
            <div className="flex items-center">
              <div className="rounded-full z-20 text-white font-bold  bg-[#161A34] h-9 w-9 flex items-center justify-center">
                2
              </div>
              <div className="h-1 hidden lg:flex flex-grow bg-[#161A34]" />
            </div>
            <h2 className="text-lg lg:mt-3 lg:ml-0 ml-12 -mt-8 font-bold ">
              Ausprobieren
            </h2>
            <p className="text-darkgray lg:ml-0 ml-12 mt-3 pr-10">
              Findet die Software Gefallen, wird ein Testzugang eingerichtet und
              in einem weiteren Meeting werden gemeinsam 4-6 Testinhalte
              erstellt.
            </p>
          </div>

          <div className="w-full relative pb-6 lg:pb-0 rounded-lg">
            <div className="flex items-center">
              <div className="rounded-full z-20 text-white font-bold  bg-[#161A34] h-9 w-9 flex items-center justify-center">
                3
              </div>
              <div className="h-1 hidden lg:flex flex-grow bg-[#161A34]" />
            </div>
            <h2 className="text-lg lg:mt-3 lg:ml-0 ml-12 -mt-8 font-bold ">
              Entscheiden
            </h2>
            <p className="text-darkgray lg:ml-0 ml-12 mt-3 pr-10">
              Es wird entschieden, welches Preismodell passend ist und der
              Nutzungsvertrag wird geschlossen.
            </p>
          </div>

          <div className="w-full pb-6 lg:pb-0 relative rounded-lg">
            <div className="absolute left-4 lg:hidden top-1 bg-white w-2 h-full" />
            <div className="flex items-center">
              <div className="rounded-full z-20 text-white font-bold  bg-[#161A34] h-9 w-9 flex items-center justify-center">
                4
              </div>
              <div className="h-1 flex-grow bg-white" />
            </div>
            <h2 className="text-lg lg:mt-3 lg:ml-0 ml-12 -mt-8 font-bold ">
              Anwenden
            </h2>
            <p className="text-darkgray lg:ml-0 ml-12 mt-3 pr-10">
              Die Software kann nun eigenständig und vollumfänglich genutzt
              werden. Bei Fragen hilft das mudioo-Team gerne.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowToStart;
