import React, { useState, useEffect, useRef } from "react";
import AudioControls from "../Assets/AudioControls";

export default function AudioguideSample() {
  const [trackProgress, setTrackProgress] = useState(0);

  const audioSrc = require("../Assets/Vorburg.mp3");

  const audioRef = useRef(new Audio(audioSrc));
  const { duration } = audioRef.current;
  const intervalRef = useRef();
  const isReady = useRef(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const currentPercentage = duration
    ? `${(trackProgress / duration) * 100}%`
    : "0%";
  const trackStyling = `
  -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))
`;

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        // toNextTrack();
        setIsPlaying(false);
        setTrackProgress(0);
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);
  };

  const onScrub = (value) => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();

      startTimer();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  //   useEffect(() => {
  //     audioRef.current.pause();

  //     audioRef.current = new Audio("../Assets/audio_sample.mp3");

  //     setTrackProgress(audioRef.current.currentTime);

  //     if (isReady.current) {
  //       audioRef.current.play();
  //       setIsPlaying(true);
  //       startTimer();
  //     } else {
  //       isReady.current = true;
  //     }
  //   }, []);

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <div className="bg-white to-white px-5 lg:px-16 lg:pb-20 pb-10 lg:pt-16 pt-0 w-full flex flex-col items-center ">
      <div className="w-full flex flex-row max-w-5xl items-center relative ">
        <div className="lg:w-7/12 lg:pr-14 lg:block flex flex-col items-center w-full">
          <h2 className="font-bold text-start  w-full text-3xl mt-8 lg:mt-0 leading-[44px] ">
            Hörprobe - KI Audioguide
          </h2>
          <p className="text-darkgray  lg:flex text-center text-start text-lg mt-3 lg:mt-5">
            Dank moderner Text-zu-Sprache-Kl können Sie mithilfe von mudioo
            Audioguides erstellen, ohne auf Sprecher angewiesen zu sein. Dazu
            geben Sie einfach den Audioguide-Text ein, wählen eine Stimme aus
            und erhalten eine Audiodatei.
            <br />
            <br /> Klicken Sie auf die Wiedergabetaste und hören Sie selbst, wie
            das Museum der Turmhügelburg Lütjenburg einen Audioguide über die
            “Vorburg Anlage” erstellt hat.
          </p>

          <div className="lg:h-16  hidden lg:flex lg:w-64  cursor-pointer lg:px-2 px-4 border-border lg:border-white hover:border-border transition-all hover:bg-lightgray lg:bg-white bg-[#FCFBFF] lg:bg-none border rounded-lg mt-8  items-center">
            <AudioControls
              isPlaying={isPlaying}
              onPlayPauseClick={setIsPlaying}
            />
            <div className="ml-3 text-dark  ">
              <div className="font-semibold">In der Vorburg</div>
              {isPlaying ? (
                <>
                  <input
                    type="range"
                    value={trackProgress}
                    step="1"
                    min="0"
                    max={duration ? duration : `${duration}`}
                    className="w-full h-1 bg-darkgray rounded-lg appearance-none cursor-pointer"
                    onChange={(e) => onScrub(e.target.value)}
                    onMouseUp={onScrubEnd}
                    onKeyUp={onScrubEnd}
                    // style={{ background: trackStyling }}
                  />
                </>
              ) : (
                <>
                  <div className="font-regular h-6 text-sm text-darkgray">
                    Abspielen
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="w-full lg:hidden rounded-lg flex flex-col items-center  mt-8 lg:mt-4">
            <div className="w-full relative lg:h-40 h-72 rounded-lg overflow-hidden">
              <img
                alt="mudioo Logo"
                src={require("../Assets/vorburg.jpeg")}
                className="w-full lg:-mt-6 -mt-20 rounded-lg "
              />
              <div className="absolute w-full h-full bg-gradient-to-r from-dark opacity-60 to-dark top-0 left-0"></div>
              <div className="absolute w-full lg:bg-none bg-lightblue bottom-0 lg:px-0 lg:py-0 px-8 py-2 pt-5 lg:pt-0 lg:bottom-3 left-0 lg:left-6">
                <div className="flex items-center">
                  <AudioControls
                    isPlaying={isPlaying}
                    onPlayPauseClick={setIsPlaying}
                  />
                  <div className="lg:ml-2 ml-4">
                    <div className="font-bold lg:text-base text-lg text-dark lg:text-white">
                      In der Vorburg
                    </div>
                    <div className="lg:text-lightgray text-primary text-base lg:text-sm">
                      Turmhügelburg Lütjenburg
                    </div>
                  </div>
                </div>
                {isPlaying ? (
                  <input
                    type="range"
                    value={trackProgress}
                    step="1"
                    min="0"
                    max={duration ? duration : `${duration}`}
                    className="w-full mt-4 h-1 bg-darkgray rounded-lg appearance-none cursor-pointer"
                    onChange={(e) => onScrub(e.target.value)}
                    onMouseUp={onScrubEnd}
                    onKeyUp={onScrubEnd}
                    // style={{ background: trackStyling }}
                  />
                ) : (
                  <div className="h-6 mt-0.5"></div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-5/12 lg:block hidden pl-16">
          <div className="w-full relative h-auto rounded-lg  shadow-[lightgray] shadow">
            <img
              alt="mudioo Logo"
              src={require("../Assets/vorburg.jpeg")}
              className="w-full rounded-lg"
            />
            {/* <div className="w-60 h-14 rounded-lg shadow-lg border border-border shadow-border bg-white absolute -bottom-6 -left-10"></div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
