import React, { useState } from "react";

function GetBetaUser() {
  return (
    <div className="flex ipad:px-0 px-5 justify-center w-full ">
      <div className="w-full max-w-5xl bg-primary overflow-hidden rounded-2xl relative p-6 pt-8 pb-0 lg:p-3 lg:pt-16 flex-col items-center justify-center flex">
        <h2 className="lg:text-5xl  text-center text-2xl z-20 text-white font-bold lg:leading-[60px]">
          Jetzt Infogespräch buchen <br className="lg:block hidden" />
        </h2>
        <p className="text-base lg:text-lg lg:max-w-2xl relative text-lightblue text-center mt-4 font-semibold z-30">
          Sie haben Interesse daran, mudioo kostenlos zu testen, um zu schauen
          ob das Angebot zu Ihrer Institution passt? Kontaktieren Sie uns gerne.
        </p>
        <a
          target="_blank"
          href="https://calendly.com/christoph-v-trotha/-mudioo"
          className="bg-dark cursor-pointer font-bold h-14 z-20 w-4/5  lg:w-1/4 rounded-full lg:ml-3 lg:mt-10 mt-8 flex items-center justify-center text-white"
        >
          Jetzt Infogespräch buchen
        </a>

        <div className="mt-5 lg:mt-8 w-full lg:w-2/3 lg:items-center">
          <div className="flex lg:flex-row mb-5 flex-col">
            <div className="bg-secondary absolute -top-[470px] lg:-top-96 z-10 -left-56 lg:-left-36 w-[500px] h-[500px] rounded-full" />
            <div className="bg-secondary absolute top-36 lg:top-56 -right-44 w-96 h-96 rounded-full" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetBetaUser;
