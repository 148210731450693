import React from "react";

function Metrics() {
  return (
    <div className="w-full flex justify-center">
      <div className="w-full relative flex flex-col pt-8 lg:pt-20 lg:px-0 px-5 lg:pb-12 pb-0 max-w-5xl">
        <h2 className="text-3xl text-dark   w-full font-bold ">
          So schnell geht’s
        </h2>
        <div className="text-darkgray pr-20 text-lg mt-8 ">
          Die mudioo-Software ist so aufgebaut, dass auch Personen mit geringen
          technischen Kenntnissen in wenigen Schritten einen Multimediaguide
          erstellen können.
        </div>
        <div className="w-full mt-8 flex lg:flex-row flex-col">
          <MetricsCard
            version={1}
            time={"26 Minuten"}
            text={
              "Durchschnittliche Zeit, bis Personen nach unserer Anleitung reibungslos mit der Software umgehen können."
            }
          />
          <div className="w-10 lg:mt-0 mt-5" />
          <MetricsCard
            version={2}
            time={"5 Std. und 38 Min."}
            text={
              "Durchschnittliche Zeit, die Institutionen benötigen, um mit der Software einen mehrsprachigen Multimediaguide anzulegen."
            }
          />

          <div className="absolute h-20 -rotate-12 bottom-16 lg:bottom-14 left-4 lg:-left-10">
            <img
              alt="mudioo Logo"
              src={require("../Assets/arrow.png")}
              className="h-full scale-75 lg:scale-100 lg:mt-0 -mt-4 lg:ml-5 ml-0"
            />
          </div>
        </div>
        <div className="text-text font-semibold pl-16 pr-5 lg:px-20 text-xl lg:text-2xl leading-8 mt-8 ">
          Morgen anfangen und nach wenigen Tagen Besucher:innen mit einem
          Multimediaguide begeistern.
        </div>
      </div>
    </div>
  );
}

export default Metrics;

function MetricsCard({ time, version, text }) {
  return (
    <div className="w-full relative p-8  bg-primary rounded-lg overflow-hidden items-center">
      <div className="text-3xl font-bold text-white shrink-0 z-20">{time}</div>
      <div className="z-30 relative mt-5 flex-wrap text-[#C9CDF3] font-semibold">
        {text}
      </div>

      <div
        className={`absolute w-56 h-56  rounded-full bg-[#616FDB] ${
          version === 2 ? "-left-28 -bottom-52" : "-left-36 -bottom-36"
        }`}
      />
      <div
        className={`absolute w-56 h-56  rounded-full bg-[#616FDB] ${
          version === 2 ? "-right-20 -top-44" : "-right-36 -top-32"
        }`}
      />
    </div>
  );
}
