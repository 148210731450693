import React from "react";

function Targetgroup() {
  return (
    <div className="w-full flex justify-center mt-20 lg:mt-10 ">
      <div className="w-full max-w-5xl justify-between items-center lg:items-start lg:flex-row flex-col ipad:px-0 px-5 flex lg:mb-8 ">
        <div className="lg:mr-28 w-full">
          <h2 className="font-bold text-3xl mt-0 lg:mt-0 leading-[44px] ">
            Für wen ist mudioo geeignet?
          </h2>
          <p className="text-darkgray hidden lg:block lg:text-left text-lg  lg:mt-5">
            {/* mudioo ist die ideale Lösung für Zahlreiche Institutionen und
            Anwendungsfelder. Mit der vielseitigen Plattform können sie
            effizient Wissen vermitteln, interaktive Lernerlebnisse schaffen und
            ihre Bildungsangebote an eine Vielzahl von Zielgruppen anpassen. */}
            Die mudioo Software ist für verschiedene Anwendungsbereiche
            geeignet. Institutionen, wie Museen, Tourismusverbände,
            Stadtrundgänge, Gedenkstätten, Denkmäler, zeitlich begrenzte
            Ausstellungen, Burgen, Schlösser, Kirchen, Botanische Gärten und
            Parks können mudioo nutzen, um Besucher:innen für ihre Inhalte zu
            begeistern.
          </p>
        </div>
        <div className="w-full">
          <div className="lg:w-96 lg:h-96 h-auto w-full mt-10 lg:mt-0 lg:-mr-10 shrink-0 relative rounded-lg">
            {/* Museen */}
            <div
              style={{
                boxShadow: "0px 0px 15px #f2f3fa",
              }}
              className="w-full lg:w-auto lg:absolute mb-3 lg:mb-0 -top-0 -left-5 bg-white  p-4 lg:p-2 flex  items-center  rounded-2xl"
            >
              <div className="rounded-full shrink-0 bg-primary h-10  w-10 flex justify-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="white"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
                  />
                </svg>
              </div>
              <div className="text-text font-bold  lg:flex text-lg ml-3">
                Museen
              </div>
            </div>

            {/* Museen */}
            <div
              style={{
                boxShadow: "0px 0px 15px #f2f3fa",
              }}
              className="w-full lg:w-auto lg:absolute mb-3  lg:mb-0 top-32 left-2 bg-white  p-4 lg:p-2 flex  items-center  rounded-2xl"
            >
              <div className="rounded-full shrink-0 bg-primary h-10  w-10 flex justify-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="white"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </div>
              <div className="text-text font-bold flex text-lg ml-3">
                Zeitlich begrenzte Ausstellungen
              </div>
            </div>

            {/* Museen */}
            <div
              style={{
                boxShadow: "0px 0px 15px #f2f3fa",
              }}
              className="w-full lg:w-auto lg:absolute mb-3 lg:mb-0 -top-4 left-32 bg-white  p-4 lg:p-2 flex  items-center  rounded-2xl"
            >
              <div className="rounded-full shrink-0 bg-primary h-10  w-10 flex justify-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="white"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z"
                  />
                </svg>
              </div>
              <div className="text-text font-bold  flex text-lg ml-3">
                Tourismusverbände/ Stadtrundgänge
              </div>
            </div>

            {/* Museen */}
            <div
              style={{
                boxShadow: "0px 0px 15px #f2f3fa",
              }}
              className="w-full lg:w-auto lg:absolute mb-3 lg:mb-0 top-48 -left-5 bg-white  p-4 lg:p-2 flex  items-center  rounded-2xl"
            >
              <div className="rounded-full shrink-0 bg-primary h-10  w-10 flex justify-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="white"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
                  />
                </svg>
              </div>
              <div className="text-text font-bold  flex text-lg ml-3">
                Burgen/ Schlösser/ Kirchen
              </div>
            </div>

            {/* Museen */}
            <div
              style={{
                boxShadow: "0px 0px 15px #f2f3fa",
              }}
              className="w-full lg:w-auto lg:absolute top-72 mb-3 lg:mb-0 lg:-mt-8 right-5 bg-white  p-4 lg:p-2 flex  items-center  rounded-2xl"
            >
              <div className="rounded-full shrink-0 bg-primary h-10  w-10 flex justify-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="white"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                  />
                </svg>
              </div>
              <div className="text-text font-bold  flex text-lg ml-3">
                Botanische Gärten / Parks
              </div>
            </div>

            {/* Museen */}
            <div
              style={{
                boxShadow: "0px 0px 15px #f2f3fa",
              }}
              className="w-full lg:w-auto lg:absolute shrink-0 top-16 left-0 bg-white  p-4 lg:p-2 flex  items-center  rounded-2xl"
            >
              <div className="rounded-full shrink-0 bg-primary h-10  w-10 flex justify-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="white"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
                  />
                </svg>
              </div>
              <div className="text-text font-bold shrink-0  flex text-lg ml-3">
                Gedenkstätten / Denkmäler
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Targetgroup;
