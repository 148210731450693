import React, { useEffect } from "react";
import { ReactComponent as AppStore } from "../Assets/AppStore.svg";

function RedirectApp() {
  function redirectToStore() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location = "https://apps.apple.com/us/app/mudioo/id6452048103";
    } else if (/android/i.test(userAgent)) {
      window.location =
        "https://play.google.com/store/apps/details?id=com.mudioo";
    } else {
    }
  }

  useEffect(() => {
    redirectToStore();
  }, []);

  return (
    <div className="w-full">
      <div className="w-full h-screen flex items-center flex-col justify-center ">
        <h2 className="font-bold  text-center w-full  text-4xl mt-8 lg:mt-0 leading-[44px] ">
          mudioo-App jetzt herunterladen
        </h2>
        <div className="lg:w-1/3 w-full px-5 text-center text-lg mt-6 text-darkgray">
          Unsere App macht Ausstellungsbesuche zum Abenteuer. Finde Museen,
          erkunde Exponate, tauche in fesselnde Geschichten ein. Jetzt
          downloaden!
        </div>

        <div className="flex lg:flex-row items-center flex-col mt-6">
          <a href="https://apps.apple.com/us/app/mudioo/id6452048103">
            <AppStore />
          </a>

          <a
            className="lg:ml-6"
            href="https://play.google.com/store/apps/details?id=com.mudioo"
          >
            <img
              alt="playStore"
              src={require("../Assets/playstore.png")}
              className="h-12 lg:-mt-1 mt-4"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default RedirectApp;
