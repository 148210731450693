import Contact from "../Components/Contact";
import Menubar from "../Components/Menubar";

function Contactpage() {
  return (
    <div>
      <Menubar />

      <div className=" bg-gradient-to-b from-[#FCFBFF] overflow-hidden flex flex-col items-center to-white relative z-30 w-full">
        <div className="w-[800px] h-[800px] lg:-right-[400px] lg:-top-[400px] -right-[500px] -top-[500px] rounded-full rotate-[45deg]  bg-gradient-to-r from-[#FCFBFF] to-[#F6F7FF] absolute z-10" />
        <div className="w-[1000px] h-[1200px] lg:-left-[500px] lg:top-[300px] -right-[900px] top-[0px]  rounded-full rotate-45  bg-gradient-to-l from-[#FCFBFF] to-[#F6F7FF] absolute z-10" />

        <div className=" h-28 lg:h-32"></div>
        <h1 className="font-bold text-center z-20 text-3xl lg:text-5xl max-w-4xl lg:mt-10 text-text px-5 leading-[40px]  lg:leading-[65px]">
          Jetzt Demo anfordern
        </h1>
        <p className="text-darkgray z-20 lg:px-0 px-5  leading-[30px] lg:leading-[32px]  text-lg max-w-3xl w-full mb-20 lg:-mb-10 mt-3 text-center">
          Sie haben Interesse daran, mudioo kostenlos zu testen, um zu schauen
          ob das Angebot zu Ihrer Institution passt? Kontaktieren Sie uns gerne.
          Wir werden Ihnen den Funktionsumfang und die Einsatzmöglichkeiten der
          mudioo-Software zeigen und Sie auf den ersten Schritten begleiten.
        </p>
        <Contact type="Betaanfrage" />
      </div>
    </div>
  );
}

export default Contactpage;
