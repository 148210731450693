import React, { useState, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { isMobile } from "react-device-detect";
import "../App.css";
import { ReactComponent as HeroSVG } from "../Assets/svg/hero2.svg";
import { createPortal } from "react-dom";
import LoadingSpinner from "./LoadingSpinner";

function Hero() {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [portalTarget, setPortalTarget] = useState(null);

  const { scrollY } = useScroll();
  const translateHeroY = useTransform(scrollY, [400, 800], [0, 250]);
  const translateHeroYmobile = useTransform(scrollY, [0, 550], [1, 1.3]);
  const translateHeroX = useTransform(scrollY, [520, 800], [0, 250]);
  const scaleHero = useTransform(scrollY, [520, 800], [1, 0.55]);
  const translateAnimBoxLeft = useTransform(scrollY, [220, 1500], [-80, -2000]);
  const translateAnimBoxTop = useTransform(scrollY, [220, 1500], [0, 1200]);
  const translateAnimBoxRight = useTransform(scrollY, [200, 1500], [100, 2000]);
  const translateAnimBoxRightSlow = useTransform(
    scrollY,
    [230, 2300],
    [0, 2000]
  );
  const opacityAnim = useTransform(scrollY, [350, 460], [1, 0]);
  const opacityAnimText = useTransform(scrollY, [630, 850], [0, 1]);
  const translateLeftCircleY = useTransform(scrollY, [0, 1000], [0, 500]);
  // const scrollLeftPhone = useTransform(scrollY, [800, 1100], [200, 0]);

  const translateAnimBoxRightSecond = useTransform(
    scrollY,
    [200, 1200],
    [0, 900]
  );

  const cardVariants = {
    offscreen: {
      y: isMobile ? 10 : 50,
    },
    onscreen: {
      y: 0,

      transition: {
        type: "tween",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    // Disable scrolling
    if (videoPlaying) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    // Cleanup to enable scrolling again when the component unmounts
    return () => {
      document.body.style.overflow = "";
    };
  }, [videoPlaying]);

  useEffect(() => {
    const portalDiv = document.createElement("div");
    portalDiv.id = "video-portal";
    document.body.appendChild(portalDiv);
    setPortalTarget(portalDiv);

    // Cleanup the created div when component unmounts
    return () => {
      document.body.removeChild(portalDiv);
    };
  }, []);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  return (
    <div className="bg-gradient-to-b from-[#FCFBFF] to-[#FCFBFF] justify-center  w-full lg:h-auto h-screen relative flex lg:pt-16 pb-14 flex-col items-center ">
      <motion.div
        style={screenSize.width < 980 ? {} : { y: translateLeftCircleY }}
        className="w-[800px] h-[800px] lg:-left-[400px] lg:-top-[400px] -left-[500px] -top-[500px] rounded-full rotate-[45deg]  bg-gradient-to-r from-[#FCFBFF] to-[#F6F7FF] absolute z-10"
      />
      <motion.div
        style={{ opacity: opacityAnim }}
        className="w-[1200px] h-[1200px] lg:-right-[500px] lg:top-[500px] -right-[800px] top-[450px]  rounded-full rotate-45  bg-gradient-to-l from-[#FCFBFF] to-[#F6F7FF] absolute z-10"
      />
      <div className="lg:mt-32 mt-20 px-5 flex flex-col z-20  text-center items-center ">
        <h1 className="font-bold  text-3xl lg:text-5xl max-w-3xl lg:mt-10 text-text leading-[40px]  lg:leading-[65px]">
          {/* Erstellen Sie schnell <br className="lg:hidden" /> und kostengünstig
          <br /> einen <span>Audioguide</span>
          für Ihr <br className="lg:hidden" /> Museum */}
          Ein Multimediaguide <div className="block lg:hidden" /> zum
          <div className="hidden lg:block" /> Selbsterstellen
        </h1>

        <p className="max-w-4xl  text-lg lg:text-xl text-darkgray leading-[30px] lg:leading-[32px] mt-6">
          Mit mudioo können Sie als Museum, Ausstellung, Tourismusverband oder
          Kultureinrichtung Ihre Inhalte digitalisieren und Audioguides oder
          digitale Rundgänge für Ihre Besucher:innen selbst erstellen.
        </p>
        <a
          className="bg-primary text-white hover:bg-secondary transition-all font-bold mt-10 lg:mt-12 mb-56 text-base rounded-full px-8 py-4 lg:py-3"
          target="_blank"
          href="https://calendly.com/christoph-v-trotha/-mudioo"
        >
          Infogespräch buchen
        </a>
      </div>
      <motion.div
        // initial={screenSize.width < 980 ? "onscreen" : "onscreen"}
        // whileInView="onscreen"
        // viewport={{ once: true, amount: 0.1 }}
        // variants={cardVariants}
        style={
          screenSize.width < 980
            ? {}
            : {
                scaleX: scaleHero,
                scaleY: scaleHero,
                x: translateHeroX,
                y: translateHeroY,
                boxShadow: "0px 0px 20px #EFF1F5",
              }
        }
        onClick={() => setVideoPlaying(true)}
        className="max-w-4xl group cursor-pointer w-full lg:px-0 px-5 lg:border-8 z-20 border-white absolute  lg:-bottom-[280px] ipad:-bottom-[320px] lg:top-auto top-3/4 rounded-2xl"
      >
        {/* <img
          alt="Hero"
          className="rounded-xl lg:shadow-none shadow-xl lg:border-0 border-8 border-white "
          src={require("../Assets/hero.png")}
        /> */}
        <div className="w-full aspect-video rounded-xl overflow-hidden rounded-xl lg:shadow-none shadow-xl lg:border-0 border-0 border-white ">
          <motion.img
            style={
              screenSize.width < 980
                ? { scaleX: translateHeroYmobile, scaleY: translateHeroYmobile }
                : {}
            }
            alt="Hero"
            className="h-full object-cover"
            src={require("../Assets/thumbnail4.png")}
          />
        </div>
        {/* {videoPlaying && (
          <div className="w-full h-full rounded-lg overflow-hidden bg-white absolute z-20 top-0 ">
            <iframe
              src="https://player.vimeo.com/video/947988368?autoplay=1&amp;?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              className="w-full h-full "
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              title="mudioo - Ihr ganzheitliches Konzept für digitale Wissensvermittlung"
            ></iframe>
          </div>
        )} */}

        <div className="w-full h-full absolute overflow-hidden flex flex-col items-center justify-center  rounded-xl z-10 top-0">
          <div className="w-full h-full lg:right-0 right-10 absolute flex items-center justify-center bg-[#000000]  z-20 opacity-25 rounded-xl top-0"></div>
          <div className="w-16 h-16 lg:mr-0 mr-10 flex items-center justify-center cursor-pointer group-hover:bg-primary group-hover:text-white active:text-white active:bg-primary transition-all rounded-full bg-white text-dark  z-30">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-7 ml-1 h-7"
            >
              <path
                fill-rule="evenodd"
                d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div className="text-white z-20 lg:mr-0 mr-10 mt-3  font-bold">
            Video abspielen
          </div>
        </div>
        {/* <div className="bg-white  relative w-full">
          <HeroSVG />
        </div> */}

        <motion.div
          style={{
            boxShadow: "0px 0px 15px #f2f3fa",
            x: translateAnimBoxRightSecond,
            opacity: opacityAnim,
          }}
          className="absolute ipad:flex z-20 hidden -top-10  bg-white  p-4 items-center -right-28 rounded-2xl"
        >
          <div className="rounded-full shrink-0 bg-primary h-12 w-12 flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                stroke-linejoin="round"
                stroke="white"
                d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
              />
            </svg>
          </div>
          <div className="text-text hidden lg:flex font-bold text-lg ml-3">
            KI - Unterstützung
          </div>
        </motion.div>

        <motion.div
          style={{
            boxShadow: "0px 0px 15px #f2f3fa",
            x: translateAnimBoxRight,
            opacity: opacityAnim,
          }}
          className="absolute top-16 bg-white z-20 -right-24  p-4 ipad:flex hidden items-center  rounded-2xl"
        >
          <div className="rounded-full shrink-0 bg-primary h-12 w-12 flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="white"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
              />
            </svg>
          </div>
          <div className="text-text font-bold hidden lg:flex text-lg ml-3">
            Inklusive Funktionen
          </div>
        </motion.div>

        <motion.div
          style={{
            boxShadow: "0px 0px 15px #f2f3fa",
            x: translateAnimBoxRightSlow,
            opacity: opacityAnim,
          }}
          className="absolute top-44 bg-white z-20  p-4 ipad:flex hidden items-center -right-36 rounded-2xl"
        >
          <div className="rounded-full shrink-0 bg-primary h-12 w-12 flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="white"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802"
              />
            </svg>
          </div>
          <div className="text-text font-bold hidden lg:flex text-lg ml-3">
            Verschiedene <br />
            Sprachen wählen
          </div>
        </motion.div>

        <motion.div
          style={{
            boxShadow: "0px 0px 15px #f2f3fa",
            x: translateAnimBoxLeft,
            y: translateAnimBoxTop,
            opacity: opacityAnim,
          }}
          className="absolute -top-8  z-20 bg-white p-4 flex   rounded-2xl"
        >
          <div className="rounded-full shrink-0 bg-primary h-12 w-12 ipad:flex hidden justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                stroke-linejoin="round"
                stroke="white"
                d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 01-.657.643 48.39 48.39 0 01-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 01-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 00-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 01-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 00.657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 01-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 005.427-.63 48.05 48.05 0 00.582-4.717.532.532 0 00-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 00.658-.663 48.422 48.422 0 00-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 01-.61-.58v0z"
              />
            </svg>
          </div>
          <div className="text-text  hidden ipad:flex hidden font-bold text-lg ml-3">
            Bild, Text, Audio,
            <br /> Video, GPS
          </div>
        </motion.div>
      </motion.div>
      <motion.div
        style={{ opacity: screenSize.width < 980 ? "100" : opacityAnimText }}
        className="max-w-6xl w-full absolute z-10 lg:text-left -bottom-[340px] lg:-bottom-[410px] lg:top-auto  "
      >
        <div className="lg:w-1/2 flex flex-col lg:items-start items-center  w-full px-5 ipad:px-10">
          <h2 className="font-bold text-center lg:text-left text-3xl leading-[44px]">
            Was ist mudioo?
          </h2>
          <p className="text-darkgray text-center lg:text-left text-lg lg:mt-5 mt-3 lg:pr-16">
            {/* <p className="max-w-4xl  text-lg lg:text-xl text-darkgray leading-[30px] lg:leading-[32px] mt-6"> */}
            mudioo ist Ihr ganzheitliches Konzept für{" "}
            <span className="font-bold text-dark">
              digitale Wissensvermittlung
            </span>
            ! Schauen Sie sich jetzt unser Video an und erfahren Sie alles über
            mudioo.
            {/* Einfach Ausstellungen selbst anlegen und mit Bildern, Texten, Audios
            und Videos versehen. */}
          </p>
          <div
            onClick={() => setVideoPlaying(true)}
            className={`inline-flex mt-8 items-center rounded-full cursor-pointer hover:bg-secondary transition-all px-5 lg:px-4 py-3 lg:py-2.5 text-white text-base lg:text-sm font-semibold bg-primary`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="lg:w-6 lg:h-6 w-8 h-8 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.91 11.672a.375.375 0 0 1 0 .656l-5.603 3.113a.375.375 0 0 1-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112Z"
              />
            </svg>
            Video ansehen
          </div>
        </div>
      </motion.div>

      {videoPlaying &&
        portalTarget &&
        createPortal(
          <div className="fixed h-screen w-screen top-0 z-50 flex items-center justify-center">
            <div className="w-full h-full bg-[#00000C] z-10 absolute top-0 left-0 opacity-80" />
            <div className="w-24 h-24 bg-white rounded-lg flex items-center z-20 justify-center absolute">
              <LoadingSpinner circleColor={"#b9d0fa"} color={"#3545D1"} />
            </div>
            <div
              onClick={() => setVideoPlaying(false)}
              className="w-full h-full items-center justify-center flex absolute z-30"
            >
              <iframe
                src="https://player.vimeo.com/video/947988368?autoplay=1&amp;?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                className="aspect-video w-full lg:w-auto lg:h-4/5 "
                allow="autoplay; fullscreen; clipboard-write"
                title="mudioo - Ihr ganzheitliches Konzept für digitale Wissensvermittlung"
              ></iframe>

              <div
                onClick={() => setVideoPlaying(false)}
                className="w-10 cursor-pointer h-10 cursor-pointer bg-white rounded-full text-dark fixed lg:top-12 lg:right-12 top-8 right-8 flex items-center justify-center "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>,
          portalTarget
        )}
    </div>
  );
}

export default Hero;
