import Menubar from "../Components/Menubar";
import Newsletter from "../Components/Newsletter";
import Values from "../Components/Values";

function Aboutus() {
  return (
    <div className="font-nunito overflow-hidden pb-20 ">
      <Menubar />
      <div className="w-full flex bg-gradient-to-b from-[#FCFBFF] to-white pt-20 pb-20 border-white border-b border-t  pt-0  flex-col items-center">
        <div className="w-full h-28"></div>
        <div className=" max-w-5xl mt-20 flex items-center justify-between w-full  ">
          <div className="w-2/5  aspect-square shadow-xl overflow-hidden rounded-xl">
            <img
              alt="mudioo team"
              className="object-cover h-full"
              src={require("../Assets/Team.jpg")}
            />
          </div>
          <div className="w-3/5 ml-16">
            <h2 className="font-bold leading-[55px] text-5xl mb-8 leading-[44px]">
              Moin 👋
              <br /> wir sind{" "}
              <span className="text-primary text-5xl">mudioo</span>
            </h2>
            {/* <h2 className="font-bold text-primary text-8xl leading-[44px]">
              mudioo
            </h2> */}
            <p className="text-lg text-darkgray mt-12">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet.
            </p>
          </div>
        </div>
      </div>
      <Values />
      <Newsletter />
    </div>
  );
}

export default Aboutus;
